// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Modal, Form, Input } from 'antd'
import { API } from '../../../../api'
import '../index.scss'

const Page: React.FC<any> = ({visible, modalChild, editObj}) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({...editObj})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, editObj])

  const validatorCheckName = async (rule: any, value: any) => {
    if (value) {
      const res: any = await API.checkVenueName({
        id: editObj.id || undefined,
        venueName: value
      })
      if (!res) return false
      const { code, data, message } = res
      if (code === '000000') {
        if (data) {
          return Promise.resolve()
        } else {
          return Promise.reject('场馆名称重复')
        }
      } else {
        return Promise.reject(message)
      }
    } else {
      return Promise.reject('请输入场馆名称')
    }
  }

  const submitForm = async (values: any) => {
    setConfirmLoading(true)
    const res: any = await API.createVenue({
      id: editObj.id || undefined,
      venueName: values.venueName,
      venueContract: values.venueContract,
      venueMobile: values.venueMobile,
      venueAddress: values.venueAddress
    })
    setConfirmLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      Message.success('操作成功')
      modalChild(true)
      handleCancel()
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      submitForm(values)
    } catch (errorInfo) {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmLoading(false)
    modalChild(false)
  }
  
  return (
    <Modal 
      title="新增场馆" 
      width={500} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-account-modal"
    >
      <Form preserve={false} form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
        <Form.Item 
          label="场馆名称" 
          name="venueName"
          required
          rules={[
            { validator: validatorCheckName }
          ]}
          validateTrigger="onBlur"
        >
          <Input placeholder="请输入场馆名称" />
        </Form.Item>
        <Form.Item 
          label="联系人" 
          name="venueContract"
          rules={[{ required: true, message: '请输入联系人' }]}
        >
          <Input placeholder="请输入联系人" />
        </Form.Item>
        <Form.Item 
          label="联系电话" 
          name="venueMobile"
          required
          rules={[
            {
              validator: (rule, value) => {
                let mobileReg = /^1[3-9]\d{9}$/, landlineReg = /^0\d{2,3}-?\d{7,8}$/;
                if (value) {
                  if (mobileReg.test(value) || landlineReg.test(value)) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject("请输入正确的联系电话")
                  }
                } else {
                  return Promise.reject("请输入联系电话")
                }
              }
            }
          ]}
        >
          <Input placeholder="请输入联系电话" />
        </Form.Item>
        <Form.Item 
          label="场馆地址" 
          name="venueAddress"
          rules={[{ required: true, message: '请输入场馆地址' }]}
        >
          <Input placeholder="请输入场馆地址" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
