import React, { useState, useEffect } from 'react'
import { App, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import LineChart from './components/line_chart'
import { API } from '../../api'
import { fileResourcePath } from '../../utils/index'
import './index.scss'

const dayjs = require('dayjs')

interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC = () => {
  const { message: Message } = App.useApp()
  const navigate = useNavigate()
  const [homeData, setHomeData] = useState<any>({})
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [dataSource, setDataSource] = useState<any>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const linkList = [
    { title: '预约列表', path: '/class-manage/appointment-list', icon: require('../../assets/images/appointment.png') },
    { title: '订单列表', path: '/member-manage/order-list', icon: require('../../assets/images/order.png') },
    { title: '会员列表', path: '/member-manage/member-list', icon: require('../../assets/images/member.png') },
    { title: '课程列表', path: '/class-manage/class-list', icon: require('../../assets/images/class.png') },
  ]
  const todayStr = dayjs().format('YYYY-MM-DD')

  const columns = [
    {
      title: '预约课程',
      dataIndex: 'courseName',
      key: 'courseName',
      width: 150,
      ellipsis: true
    },
    {
      title: '课程时间',
      dataIndex: 'reserveTime',
      key: 'reserveTime',
      width: 150,
      render: (text: any, record: any) => `${dayjs(record.reserveDate).format('YYYY-MM-DD')}(${text})`,
    },
    {
      title: '微信昵称',
      dataIndex: 'weixinName',
      key: 'weixinName',
      width: 150,
      ellipsis: true
    },
    {
      title: '微信头像',
      dataIndex: 'weixinUrl',
      key: 'weixinUrl',
      width: 150,
      render: (text: any) => (<div className='yg-weixin-url-col'>
        <img src={fileResourcePath(text)} alt='头像' />
      </div>),
    },
    {
      title: '会员手机号码',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
      width: 150,
      ellipsis: true
    },
    {
      title: '预约时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 150,
      ellipsis: true
    },
  ]

  useEffect(() => {
    getHomeData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo])

  const getHomeData = async () => {
    const res: any = await API.homePageData({})
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      let newData = data || {}
      newData.appointmentCount = 0
      let list = newData.reserveList || []
      newData.appointmentCount = list[list.length - 1]?.reserveNum || 0
      setHomeData(newData)
    } else {
      Message.error(message)
    }
  }

  const getList = async () => {
    let params: any = {
      page: pageInfo.current,
      size: pageInfo.pageSize,
      reserveStartDate: todayStr,
      reserveEndDate: todayStr,
    }
    setTableLoading(true)
    const res: any = await API.reserveDetailList(params)
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const redirectTo= (path: any) => {
    navigate(path)
  }

  return (
    <div className='home-page'>
      <div className='link-list'>
        {linkList.map((ele: any, index: number) => (<div key={index} className='link-item' onClick={() => redirectTo(ele.path)}>
          <span className='link-title'>{ele.title}</span>
          <div className='link-icon'>
            <img alt='图标' src={fileResourcePath(ele.icon)} />
          </div>
        </div>))}
      </div>
      <div className='center-part'>
        <div className='member-statistic-card'>
          <div className='new-count-txt'>新增会员数</div>
          <div className='new-count-num'>{homeData.createCardNum || 0}</div>
          <div className='count-card-wrapper'>
            <div className='renew-count-card'>
              <div className='count-txt'>续费会员数</div>
              <div className='count-num'>{homeData.renewCardNum || 0}</div>
            </div>
            <div className='total-count-card'>
              <div className='count-txt'>会员总数</div>
              <div className='count-num'>{homeData.cardNum || 0}</div>
            </div>
          </div>
        </div>
        <div className='appointment-statistic-card'>
          <div className='appointment-title'>预约数（次）</div>
          <div className='appointment-count'>
            <span>{homeData.appointmentCount || 0}</span>
            <span>（今日）</span>
          </div>
          <LineChart data={homeData.reserveList || []} />
        </div>
      </div>
      <div className='appointment-list'>
        <div className='title-box'>
          今日预约
          <span>{dayjs().format('YYYY.MM.DD')}</span>
        </div>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="id"
          loading={tableLoading}
          pagination={{
            ...pageInfo,
            total,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total) => `共 ${total} 条`,
            onChange: handlePageChange,
          }}
        />
      </div>
    </div>
  )
}

export default Page
