// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Table, Input, Button } from 'antd'
import { accountStatusMap } from '../../../constants'
import { API } from '../../../api'
import lodash from 'lodash'
import { RedoOutlined } from '@ant-design/icons'
import './index.scss'

import AddAccount from './components/add_account';
interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC = () => {
  const { message: Message } = App.useApp()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [searchParams, setSearchParams] = useState<any>({
    userName: undefined
  });
  const [dataSource, setDataSource] = useState<any>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      render: (value: any, record: any, index: number) => {
        return index + 1
      },
      width: 80,
      fixed: 'left'
    },
    {
      title: '账号',
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
      ellipsis: true
    },
    {
      title: '角色',
      dataIndex: 'roleName',
      key: 'roleName',
      width: 150,
      ellipsis: true
    },
    {
      title: '姓名',
      dataIndex: 'realName',
      key: 'realName',
      width: 150,
      ellipsis: true
    },
    {
      title: '手机号码',
      dataIndex: 'userPhone',
      key: 'userPhone',
      width: 150,
      ellipsis: true
    },
    {
      title: '状态',
      dataIndex: 'userStatus',
      key: 'userStatus',
      width: 130,
      render: (text: any) => (
        <div className={['yg-status-col', lodash.get(accountStatusMap[text], 'color', '')].join(' ')}>
          <span className='yg-status-comma'></span>
          <span>{lodash.get(accountStatusMap[text], 'text', '')}</span>
        </div>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 160,
      ellipsis: true
    },
    {
      title: '修改时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 160,
      ellipsis: true
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      width: 160,
      ellipsis: true,
      fixed: 'right',
      render: (text: any, record: any) => (
        <div className='yg-operate-col'>
          <span className="yg-operate-btn" onClick={() => handleOperate('edit', { ...record })}>
            编辑
          </span>
          <span className={`yg-operate-btn ${record.userStatus + '' === '0' ? "red" : "green"}`} onClick={() => handleOperate('status', { ...record })}>
            {record.userStatus + '' === '0' ? '禁用' : '启用'}
          </span>
        </div>
      )
    },
  ]

  const scrollX: number = columns.reduce((pre: number, curr: any) => {
    return pre + (curr.width || 100)
  }, 0)

  const [modalVisible, setModalVisible] = useState<Boolean>(false)
  const [editObj, setEditObj] = useState<object>({})

  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo])

  const getList = async () => {
    setTableLoading(true)
    const res: any = await API.sysUserList({
      ...searchParams,
      page: pageInfo.current,
      size: pageInfo.pageSize,
    })
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handleFilterChange = (key: string, value: any) => {
    setSearchParams({
      ...searchParams,
      [key]: value
    })
  }

  const handleSearch = () => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    })
  }
  
  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const handleOperate = (type: string, record: object) => {
    switch (type) {
      case 'add': {
        setModalVisible(true)
        setEditObj({
          ...record,
          operateType: 'add'
        })
        break;
      }
      case 'edit': {
        getEditDetail(record)
        break;
      }
      case 'status': {
        handleStatus(record)
        break;
      }
      default:
        break;
    }
  }

  const getEditDetail = async (record: any) => {
    const res: any = await API.sysUserDetail({
      id: record.id,
    })
    if (!res) return false
    const { code, data, message } = res
    if (code === '000000') {
      setEditObj({
        ...data,
        operateType: 'edit'
      })
      setModalVisible(true)
    } else {
      Message.error(message)
    }
  }

  const handleStatus = async (record: any) => {
    const res: any = await API.disableSysUser({
      id: record.id,
      userStatus: record.userStatus + '' === '0' ? '1' : '0'
    })
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success('操作成功')
      handleSearch()
    } else {
      Message.error(message)
    }
  }

  const modalOperate = (isOk: Boolean) => {
    setModalVisible(false)
    if (isOk) {
      handleSearch()
    }
  }

  return (
    <div className='account-list-page yg-page'>
      <div className='yg-page-header'><span>账号管理</span></div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>账号</span>
          <Input
            allowClear
            placeholder="请输入账号"
            value={searchParams.userName}
            style={{ width: 180 }}
            onChange={(e) => handleFilterChange('userName', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <Button type="primary" className='yg-ghost-btn' onClick={handleSearch}>查询</Button>
        </div>
        <Button className='yg-refresh-btn' onClick={handleSearch}>
          <RedoOutlined />
        </Button>
        <div className='yg-page-filter-item-right'>
          <Button type="primary" onClick={() => handleOperate('add', {})}>新增账号</Button>
        </div>
      </div>
      <div className='yg-page-container' style={{ height: 'calc(100% - 54px - 48px)' }}>
        <div className='yg-page-main'>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={tableLoading}
            scroll={{ x: scrollX }}
            pagination={{
              ...pageInfo,
              total,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共 ${total} 条`,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
      <AddAccount visible={modalVisible} modalChild={modalOperate} editObj={editObj} />
    </div>
  )
}

export default Page
