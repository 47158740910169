export const createType = (keys: string[]) => {
  let obj: any = {};
  keys.forEach((item: string) => {
    obj[item] = item;
  })
  return obj;
}

export const formatDate = (str: any) => {
  if (!str) {
    return ''
  }
  let arr = str.split(' ')
  return arr[0] || ''
}

// 从URL中解析参数
export const getUrlParam = (href: string) => {
  let url = href.split('?');
  let result: any = {}
  if (url.length <= 1) {
    return result
  }
  let params = url[1].split('&');
  for (let i = 0; i < params.length; i++) {
    let param = params[i].split('=');
    result[param[0]] = param[1]
  }
  return result
}


/**
 * 将new Date()转化为 mm-dd
 */
 export function getHhMm(date: any) {
  let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  let result = hours + ':' + minutes;
  return result;
}

/**
 * 根据生日算年龄
 */
 export function birthdayToAge(params: any) {
  // 指定出生日期
  let birthday = new Date(params);
  // 获取当前时间
  let today = new Date();
  // 计算年龄差距
  let ageDiff = today.getFullYear() - birthday.getFullYear();
  // 检查是否已过生日
  if (today.getMonth() < birthday.getMonth() || (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate())) {
    ageDiff--;
  }
  // 输出年龄
  return ageDiff;
}


//将base64 转为Blod
export function transformBase64ToBlob(base64: string): Blob {
  let byteString: string = "";

  if (base64.split(",")[0].indexOf("base64") >= 0) {
    byteString = window.atob(base64.split(",")[1]);
  } else {
    byteString = window.unescape(base64.split(",")[1]);
  }

  const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ia], {
    type: mimeString,
  });
  return blob;
}

//将Blob转为file
export function convertBlobToFile(blob: BlobPart, fileName: string, mimeType: any, lastModified = Date.now()) {
  const file = new File([blob], fileName, { type: mimeType, lastModified });
  return file;
}

// 图片/视频资源路径
export function fileResourcePath(params: string) {
  let path: string = '';
  if (params) {
    if (params.split('/')[0] === 'data:image') { // base64格式
      path = params
    } else {
      path = `${window.location.origin}/ten/${params}` // 域名拼接(部署在 http://ten.pmarketing.cn/ten/)
    }
  }
  return path;
}