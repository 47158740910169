import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Spin, Menu, message as Message } from 'antd';
import type { MenuProps } from 'antd';
import { HomeFilled, IdcardFilled, SettingFilled, ProfileFilled, ShopFilled, ProjectFilled } from '@ant-design/icons'
import { API } from '../../api';
import { getCookie, setCookie } from '../../utils/cookie';
import Header from '../header'
import './index.scss'

interface IMenu {
  key: string;
  label: string;
  icon: any,
  children?: any[];
}

const openKeyMap: any = {
  '/class-manage/class-list': 'class-manage',
  '/class-manage/appointment-list': 'class-manage',
  '/member-manage/member-list': 'member-manage',
  '/member-manage/order-list': 'member-manage',
  '/venue-manage/coach-list': 'venue-manage',
  '/venue-manage/venue-list': 'venue-manage',
  '/venue-manage/classroom-list': 'venue-manage',
  '/material-manage/photo-manage': 'material-manage',
  '/material-manage/video-manage': 'material-manage',
  '/system-setting/role-list': 'system-setting',
  '/system-setting/account-manage': 'system-setting',
  '/system-setting/avatar-list': 'system-setting',
  '/system-setting/time-list': 'system-setting'
}

const Page: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { pathname } = location
  const rootSubmenuKeys = ['home', 'class-manage', 'member-manage', 'venue-manage', 'material-manage', 'system-setting'];
  const [openKeys, setOpenKeys] = useState(['/home']);
  const menus: IMenu[] = [
    {
      key: '/home',
      label: '首页',
      icon: <HomeFilled />
    },
    {
      key: 'class-manage',
      label: '课程管理',
      children: [
        { label: '课程列表', key: '/class-manage/class-list' },
        { label: '预约列表', key: '/class-manage/appointment-list' },
      ],
      icon: <ProfileFilled />
    },
    {
      key: 'member-manage',
      label: '会员管理',
      children: [
        { label: '会员列表', key: '/member-manage/member-list' },
        { label: '订单列表', key: '/member-manage/order-list' },
      ],
      icon: <IdcardFilled />
    },
    {
      key: 'venue-manage',
      label: '场馆管理',
      children: [
        { label: '教练列表', key: '/venue-manage/coach-list' },
        { label: '场馆列表', key: '/venue-manage/venue-list' },
        { label: '教室列表', key: '/venue-manage/classroom-list' },
      ],
      icon: <ShopFilled />
    },
    {
      key: 'material-manage',
      label: '物料管理',
      children: [
        { label: '照片墙管理', key: '/material-manage/photo-manage' },
        { label: '视频课程管理', key: '/material-manage/video-manage' },
      ],
      icon: <ProjectFilled />
    },
    {
      key: 'system-setting',
      label: '系统设置',
      children: [
        { label: '角色列表', key: '/system-setting/role-list' },
        { label: '账号管理', key: '/system-setting/account-manage' },
        { label: '头像列表', key: '/system-setting/avatar-list' },
        { label: '时间段列表', key: '/system-setting/time-list' },
      ],
      icon: <SettingFilled />
    },
  ]

  useEffect(() => {
    setLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOpenKeys(openKeyMap[pathname] ? [openKeyMap[pathname]] : [])
  }, [pathname])

  useEffect(() => {
    // judgeIsLogin()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const judgeIsLogin = async () => {
  //   setLoading(true)
  //   const res: any = await API.isLogin({
  //     token: getCookie('x-token')
  //   })
  //   const { code, data, message } = res;
  //   setLoading(false)
  //   if (code === '000000') {
  //     if (data) {
  //       navigate((pathname && pathname !== '/') ? pathname : '/home')
  //     } else {
  //       navigate((pathname && pathname !== '/') ? '/login' : '/index')
  //     }
  //   } else {
  //     Message.info(message);
  //   }
  // }

  const handleMenuClick = (item: any) => {
    if (item.key === '/login') {
      setCookie('x-token', '')
      setCookie('x-time', '')
      setCookie("x-key", '');
    }
    if (!item.disabled) {
      navigate(item.key)
    } else {
      console.log('开发中...')
    }
  }
  
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (!loading ?
    <div className='basic-layout-wrapper'>
      <div className='layout-menu-wrapper'>
        <div className="logo-wrapper">
          <img src={require('../../assets/images/logo.png')} alt="logo" />
          <span>拾月普拉提</span>
        </div>
        {<Menu 
          openKeys={openKeys} 
          onOpenChange={onOpenChange} 
          onClick={handleMenuClick} 
          selectedKeys={[pathname]} 
          mode="inline" 
          items={menus} 
          style={{ width: 208 }} 
          className="layout-menu-ul"
        />}
      </div>
      <div className='layout-main-container'>
        <Header />
        <div className='layout-page-container'>
          <Outlet />
        </div>
      </div>
    </div> : <div className='loading-page-wrapper'>
      <Spin />
    </div>
  )
}

export default Page
