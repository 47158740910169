import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, App, Table } from 'antd'
import { weekDayMap } from '../../../../../constants'
import { API } from '../../../../../api'
import { formatDate } from '../../../../../utils'
import './index.scss'

interface IProps {
  visible: boolean;
  courseInfo: any;
  handleCancel: Function;
}

interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC<IProps> = ({ visible, courseInfo, handleCancel }) => {
  const { message: Message } = App.useApp()
  const navigate = useNavigate()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<any>([])

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, record: any, index: number) => {
        return index + 1
      },
      width: 100,
    },
    {
      title: '课程日期',
      dataIndex: 'reserveDate',
      key: 'reserveDate',
      width: 150,
      render: (text: any, record: any) => text ? `${formatDate(text)}(${weekDayMap[record.courseWeekDay]})` : '',
      ellipsis: true
    },
    {
      title: '可预约数',
      dataIndex: 'courseNum',
      key: 'courseNum',
      render: (text: any) => text || 0,
    },
    {
      title: '已预约数',
      dataIndex: 'reserveNum',
      key: 'reserveNum',
      render: (text: any) => text || 0,
    },
    {
      title: '剩余可预约',
      dataIndex: 'num',
      key: 'num',
      render: (text: any, record: any) => (record.courseNum || 0) - (record.reserveNum || 0),
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      render: (text: any, record: any) => <div className='yg-operate-col'>
        <span className='yg-operate-btn' onClick={() => handleViewDetail(record)}>预约明细</span>
      </div>
    },
  ]

  useEffect(() => {
    if (visible) {
      setPageInfo({
        current: 1,
        pageSize: 10
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  useEffect(() => {
    visible && getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo])

  const getList = async () => {
    let params: any = {
      courseId: courseInfo.id,
      page: pageInfo.current,
      size: pageInfo.pageSize,
    }
    setTableLoading(true)
    const res: any = await API.reserveList(params)
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const handleViewDetail = (record: any) => {
    navigate(`/class-manage/appointment-list?reserveDate=${record.reserveDate}&courseName=${encodeURIComponent(courseInfo.courseName)}`)
  }

  return (
    <Modal
      title="预约情况"
      open={visible}
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
      width={800}
      wrapClassName="reserve-detail-modal"
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="courseId"
        className='yg-small-table'
        loading={tableLoading}
        pagination={{
          ...pageInfo,
          total,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
          onChange: handlePageChange,
        }}
      />
    </Modal>
  )
}

export default Page
