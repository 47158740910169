import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { bindActionCreators } from "redux"
import { setUserInfo } from '../../store/user/action'
import { IUser } from '../../types'
import { Button, Dropdown, Modal } from 'antd'
import type { MenuProps } from 'antd'
import localDb from '../../utils/localDb'
import { deleteCookie } from '../../utils/cookie'
import { DownOutlined } from '@ant-design/icons'
import './index.scss'

interface IProps {
  children?: any;
  userInfo: IUser;
  setUserInfo: (data: IUser) => void;
}

const Header: React.FC<IProps> = ({ children, userInfo, setUserInfo }) => {
  const navigate = useNavigate()
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    setUserInfo(localDb.get("user"))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '1') {
      logout()
    }
  };
  
  const items: MenuProps['items'] = [
    {
      label: (<span>退出登录</span>),
      key: '1',
    },
  ];
  
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  
  const logout = async () => {
    const confirmed = await modal.confirm({
      title: '提示',
      content: (
        <>确认退出系统吗？</>
      ),
    });
    if (confirmed) {
      navigate('/login')

      deleteCookie('x-token')
      deleteCookie('x-key')
      deleteCookie('x-time')
      localDb.set("user", "") // 不能使用clear，记住用户名使用localStorage存储
    }
  }

  return (
    <div className="layout-header">
      <div className="header-right">
        {
          children ? children :
          <div className="user-wrapper">
            
            <img src={require('../../assets/images/avatar.png')} alt="avatar" />
            <Dropdown menu={menuProps} placement="bottom" arrow>
              <div className='user-row'>
                <div className='user-info'>
                  <span>{userInfo.realName}</span>
                  <span>{userInfo.userPhone}</span>
                </div>
                <DownOutlined />
              </div>
            </Dropdown>
          </div>
        }
      </div>
      {contextHolder}
    </div>
  )
}

export default connect(
  (state: any) => ({
    userInfo: { ...state.user }
  }),
  (dispatch: any) => bindActionCreators({ setUserInfo }, dispatch)
)(Header)
