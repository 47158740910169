// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Modal, Form, Input, Select, InputNumber  } from 'antd'
import { API } from '../../../../api'
import '../index.scss'

const Page: React.FC<any> = ({visible, modalChild, editObj}) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [venueList, setVenueList] = useState<[]>([])

  useEffect(() => {
    if (visible) {
      getVenueList()
      form.setFieldsValue({...editObj})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, editObj])

  const getVenueList = async () => {
    const res: any = await API.venueList({
      page: 1,
      size: 10000,
    })
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setVenueList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const handleSelectVenue = (value: string) => {
  }

  const validatorCheckName = async (rule: any, value: any) => {
    if (value) {
      const res: any = await API.checkClassroomName({
        id: editObj.id || undefined,
        classroomName: value
      })
      if (!res) return false
      const { code, data, message } = res
      if (code === '000000') {
        if (data) {
          return Promise.resolve()
        } else {
          return Promise.reject('教室名称重复')
        }
      } else {
        return Promise.reject(message)
      }
    } else {
      return Promise.reject('请输入教室名称')
    }
  }
  
  const submitForm = async (values: any) => {
    setConfirmLoading(true)
    const res: any = await API.createClassroom({
      id: editObj.id || undefined,
      venueId: values.venueId,
      classroomName: values.classroomName,
      classroomNum:values.classroomNum
    })
    setConfirmLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      Message.success('操作成功')
      modalChild(true)
      handleCancel()
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      submitForm(values)
    } catch (errorInfo) {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmLoading(false)
    modalChild(false)
  }
  
  return (
    <Modal 
      title="新增教室" 
      width={400} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-account-modal"
    >
      <Form preserve={false} form={form} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
        <Form.Item 
          label="场馆" 
          name="venueId"
          rules={[{ required: true, message: '请选择场馆' }]}
        >
          <Select
            allowClear
            placeholder="请选择场馆"
            onChange={(value) => handleSelectVenue(value)}
            options={venueList}
            fieldNames={{
              label: 'venueName',
              value: 'id'
            }}
            style={{width: '180px'}}
          />
        </Form.Item>
        <Form.Item 
          label="教室名称" 
          name="classroomName"
          required
          rules={[{ validator: validatorCheckName }]}
        >
          <Input placeholder="请输入教室名称" style={{width: '180px'}} />
        </Form.Item>
        <Form.Item 
          label="可容纳学员数" 
          name="classroomNum"
          required
          rules={[
            {
              validator: (rule, value) => {
                if (value) {
                  if (value === 0 || (value && value < 0)) {
                    return Promise.reject('可容纳学员数必须大于0')
                  } else {
                    return Promise.resolve()
                  }
                } else {
                  return Promise.reject('请输入可容纳学员数')
                }
              }
            }
          ]}
        >
          <InputNumber precision={0} min={1} placeholder="请输入可容纳学员数" style={{width: '180px'}} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
