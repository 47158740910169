import React, { useState, useEffect } from 'react'
import { Table, DatePicker, Select, Input, message as Message, Button } from 'antd'
import { reserveStatusMap, courseTypeMap, courseTypeList } from '../../../constants'
import { useLocation } from 'react-router-dom'
import { getUrlParam, formatDate, fileResourcePath } from '../../../utils'
import { API } from '../../../api'
import lodash from 'lodash'
import { RedoOutlined } from '@ant-design/icons'
import './index.scss'

const dayjs = require('dayjs')
const { RangePicker } = DatePicker

interface IPageInfo {
  total: number;
  current: number;
  pageSize: number;
}

const Page: React.FC = () => {
  const location = useLocation()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    total: 0,
    current: 1,
    pageSize: 10
  });
  const [searchParams, setSearchParams] = useState<any>({
    reserveDate: undefined,
    createDate: undefined,
    reserveTime: undefined,
    courseName: undefined,
    courseClassroomId: undefined,
    courseVenueUserId: undefined,
    courseType: undefined,
    customerPhone: undefined,
    weixinName: undefined,
  });
  const [dataSource, setDataSource] = useState<any>([])
  const [timeSlotList, setTimeSlotList] = useState<any>([])
  const [classroomList, setClassroomList] = useState<any>([])
  const [coachList, setCoachList] = useState<any>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, record: any, index: number) => {
        return index + 1
      },
      width: 80,
      fixed: 'left'
    },
    {
      title: '会员号码',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
      width: 150,
      ellipsis: true
    },
    {
      title: '会员名称',
      dataIndex: 'weixinName',
      key: 'weixinName',
      width: 150,
      ellipsis: true
    },
    {
      title: '微信头像',
      dataIndex: 'weixinUrl',
      key: 'weixinUrl',
      width: 150,
      render: (text: any) => (<div className='yg-weixin-url-col'>
        <img src={fileResourcePath(text)} alt='头像' />
      </div>),
    },
    {
      title: '课程名称',
      dataIndex: 'courseName',
      key: 'courseName',
      width: 150,
      ellipsis: true
    },
    {
      title: '课程教室',
      dataIndex: 'classroomName',
      key: 'classroomName',
      width: 150,
      ellipsis: true
    },
    {
      title: '课程教练',
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
      ellipsis: true
    },
    {
      title: '课程类型',
      dataIndex: 'courseType',
      key: 'courseType',
      width: 130,
      render: (text: any) => courseTypeMap[text] || '',
    },
    {
      title: '课程时长',
      dataIndex: 'courseTimes',
      key: 'courseTimes',
      width: 150,
      ellipsis: true
    },
    {
      title: '预约日期',
      dataIndex: 'reserveDate',
      key: 'reserveDate',
      width: 150,
      render: (text: any) => formatDate(text) || '',
    },
    {
      title: '预约时间段',
      dataIndex: 'reserveTime',
      key: 'reserveTime',
      width: 150,
      ellipsis: true
    },
    {
      title: '预约状态',
      dataIndex: 'reserveStatus',
      key: 'reserveStatus',
      width: 130,
      fixed: 'right',
      render: (text: any) => (<div className={['yg-status-col', lodash.get(reserveStatusMap[text], 'color', '')].join(' ')}>
        <span className="yg-status-comma"></span>
        <span>{ lodash.get(reserveStatusMap[text], 'text', '') }</span>
      </div>)
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 180,
      fixed: 'right',
      ellipsis: true
    },
  ]

  const scrollX: number = columns.reduce((pre: number, curr: any) => {
    return pre + (curr.width || 100)
  }, 0)

  useEffect(() => {
    let routeParams = getUrlParam(location.search)
    let newParams: any = null
    if (!lodash.isEmpty(routeParams)) {
      newParams = {
        ...searchParams,
        courseName: routeParams.courseName ? decodeURIComponent(routeParams.courseName) : undefined,
        reserveDate: routeParams.reserveDate ? [dayjs(routeParams.reserveDate, "YYYY-MM-DD"), dayjs(routeParams.reserveDate, "YYYY-MM-DD")] : undefined,
      }
      setSearchParams(newParams)
    }
    getList(newParams)
    getTimeSlot()
    getCoachList()
    getClassroomList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getList = async (tempSearchParams?: any, tempPageInfo?: any) => {
    let realSearchParams = tempSearchParams || searchParams
    let realPageInfo = tempPageInfo || pageInfo
    let params: any = {
      ...realSearchParams,
      page: realPageInfo.current,
      size: realPageInfo.pageSize,
      reserveStartDate: realSearchParams.reserveDate ? realSearchParams.reserveDate[0].format('YYYY-MM-DD')  : undefined,
      reserveEndDate: realSearchParams.reserveDate ? realSearchParams.reserveDate[1].format('YYYY-MM-DD') : undefined,
      createStartDate: realSearchParams.createDate ? realSearchParams.createDate[0].format('YYYY-MM-DD')  : undefined,
      createEndDate: realSearchParams.createDate ? realSearchParams.createDate[1].format('YYYY-MM-DD') : undefined,
    }
    delete params.reserveDate
    delete params.createDate
    setTableLoading(true)
    const res: any = await API.reserveDetailList(params)
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setPageInfo({ ...pageInfo, total: data?.total || 0 })
    } else {
      Message.error(message)
    }
  }

  const getTimeSlot = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.timeSlotList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setTimeSlotList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const getCoachList = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.coachList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setCoachList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const getClassroomList = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.classroomList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setClassroomList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const handleFilterChange = (key: string, value: any) => {
    setSearchParams({
      ...searchParams,
      [key]: value
    })
  }

  const handleSearch = () => {
    let newPageInfo = {
      ...pageInfo,
      current: 1,
    }
    setPageInfo(newPageInfo)
    getList(null, newPageInfo)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    let newPageInfo = {
      ...pageInfo,
      current: newPage,
      pageSize
    }
    setPageInfo(newPageInfo)
    getList(null, newPageInfo)
  }

  return (
    <div className='appointment-list-page yg-page'>
      <div className='yg-page-header'><span>预约列表</span></div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>预约时间</span>
          <RangePicker
            value={searchParams.reserveDate}
            onChange={(date) => handleFilterChange('reserveDate', date)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>创建时间</span>
          <RangePicker
            value={searchParams.createDate}
            onChange={(date) => handleFilterChange('createDate', date)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>预约时间段</span>
          <Select
            placeholder="请选择预约时间段"
            value={searchParams.reserveTime}
            style={{ width: 220 }}
            onChange={(value) => handleFilterChange('reserveTime', value)}
            options={timeSlotList.map((ele: any) => ({
              label: ele.timeSlot, value: ele.timeSlot
            }))}
          />
        </div>
      </div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程名称</span>
          <Input
            allowClear
            placeholder="请输入课程名称"
            value={searchParams.courseName}
            style={{ width: 180 }}
            onChange={(e) => handleFilterChange('courseName', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程教室</span>
          <Select
            allowClear
            placeholder="请选择课程教室"
            value={searchParams.courseClassroomId}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('courseClassroomId', value)}
            options={classroomList.map((ele: any) => ({
              label: ele.classroomName, value: ele.id
            }))}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程教练</span>
          <Select
            placeholder="请选择课程教练"
            value={searchParams.courseVenueUserId}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('courseVenueUserId', value)}
            options={coachList.map((ele: any) => ({
              label: ele.userName, value: ele.id
            }))}
          />
        </div>
      </div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程类型</span>
          <Select
            allowClear
            placeholder="请选择课程类型"
            value={searchParams.courseType}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('courseType', value)}
            options={courseTypeList}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>会员号码</span>
          <Input
            allowClear
            placeholder="请输入会员号码"
            value={searchParams.customerPhone}
            style={{ width: 180 }}
            onChange={(e) => handleFilterChange('customerPhone', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>微信昵称</span>
          <Input
            allowClear
            placeholder="请输入微信昵称"
            value={searchParams.weixinName}
            style={{ width: 180 }}
            onChange={(e) => handleFilterChange('weixinName', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <Button type="primary" className='yg-ghost-btn' onClick={handleSearch}>查询</Button>
        </div>
        <Button className='yg-refresh-btn' onClick={handleSearch}>
          <RedoOutlined />
        </Button>
      </div>
      <div className='yg-page-container' style={{ height: 'calc(100% - 54px - 144px)' }}>
        <div className='yg-page-main'>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={tableLoading}
            scroll={{ x: scrollX }}
            pagination={{
              ...pageInfo,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共 ${total} 条`,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Page
