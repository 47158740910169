const api: any = {
  /******************** 公共分类 ********************/
  homePageData: { // 首页数据
    method: 'get',
    url: '/yoga/console/homePage/homePageData',
  },
  normalUpload: { // 普通文件上传
    method: 'postForm',
    url: '/yoga/console/file/normalUpload'
  },
  /******************** 登录登出 ********************/
  sendCode: { // 登录发送验证码
    method: 'post',
    url: '/yoga/console/sendCode'
  },
  login: { // 登录
    method: 'post',
    url: '/yoga/console/login'
  },
  logout: { // 登出
    method: 'postUrlencoded',
    url: '/yoga/console/logout'
  },
  /******************** 课程管理 ********************/
  getCourseList: { // 课程列表
    method: 'post',
    url: '/yoga/console/course/list'
  },
  createCourse: { // 新增课程
    method: 'post',
    url: '/yoga/console/course/create'
  },
  reserveList: { // 预约情况列表
    method: 'post',
    url: '/yoga/console/course/reserveList'
  },
  deleteCourse: { // 删除课程
    method: 'postUrlencoded',
    url: '/yoga/console/course/deleteOne'
  },
  deleteCourseFinal: { // 超级删除课程
    method: 'postUrlencoded',
    url: '/yoga/console/course/deleteFinal'
  },
  modifyCourse: { // 编辑课程
    method: 'post',
    url: '/yoga/console/course/modifyCourse'
  },
  calendarData: { // 课程日历数据
    method: 'post',
    url: '/yoga/console/course/calendarData'
  },
  checkCourseName: { // 校验课程名称是否重复
    method: 'post',
    url: '/yoga/console/course/checkCourseName'
  },
  courseDetail: { // 课程详情
    method: 'postUrlencoded',
    url: '/yoga/console/course/detail'
  },
  reserveDetailList: { // 预约列表
    method: 'post',
    url: '/yoga/console/reserveDetail/list'
  },
  /******************** 会员管理 ********************/
  customerList: { // 会员列表
    method: 'post',
    url: '/yoga/console/customer/list'
  },
  createCard: { // 续费或开卡
    method: 'post',
    url: '/yoga/console/customer/createCard'
  },
  modifyIntegral: { // 积分调整
    method: 'post',
    url: '/yoga/console/customer/modifyIntegral'
  },
  integralList: { // 积分明细
    method: 'post',
    url: '/yoga/console/customer/integralList'
  },
  reserveCourse: { // 预约课程
    method: 'post',
    url: '/yoga/console/customer/reserveCourse'
  },
  cardOrderList: { // 订单列表
    method: 'post',
    url: '/yoga/console/cardOrder/list'
  },
  createCustomer: { // 新增会员
    method: 'post',
    url: '/yoga/console/customer/createCustomer'
  },
  updateCustomer: { // 编辑会员
    method: 'post',
    url: '/yoga/console/customer/updateCustomer'
  },
  checkCustomerName: { // 新增会员-校验会员名称重复
    method: 'postUrlencoded',
    url: '/yoga/console/customer/checkCustomerName'
  },
  checkCustomerPhone: { // 新增会员-校验会员号码存在
    method: 'postUrlencoded',
    url: '/yoga/console/customer/checkCustomerPhone'
  },
  /******************** 场馆管理 ********************/
  coachList: { // 教练列表
    method: 'post',
    url: '/yoga/console/vueneUser/list'
  },
  createCoach: { // 新增教练
    method: 'post',
    url: '/yoga/console/vueneUser/create'
  },
  deleteCoach: { // 删除教练
    method: 'postUrlencoded',
    url: '/yoga/console/vueneUser/delete'
  },
  checkCoachName: { // 校验教练名称是否重复
    method: 'post',
    url: '/yoga/console/vueneUser/chcekUserName'
  },
  coachDetail: { // 教练详情
    method: 'postUrlencoded',
    url: '/yoga/console/vueneUser/detail'
  },
  venueList: { // 场馆列表
    method: 'post',
    url: '/yoga/console/vuene/list'
  },
  createVenue: { // 新增场馆
    method: 'post',
    url: '/yoga/console/vuene/create'
  },
  deleteVenue: { // 删除场馆
    method: 'postUrlencoded',
    url: '/yoga/console/vuene/delete'
  },
  checkVenueName: { // 校验场馆名称是否重复
    method: 'post',
    url: '/yoga/console/vuene/chcekVenueName'
  },
  venueDetail: { // 场馆详情
    method: 'postUrlencoded',
    url: '/yoga/console/vuene/detail'
  },
  classroomList: { // 教室列表
    method: 'post',
    url: '/yoga/console/vueneClassroom/list'
  },
  createClassroom: { // 新增教室
    method: 'post',
    url: '/yoga/console/vueneClassroom/create'
  },
  deleteClassroom: { // 删除教室
    method: 'postUrlencoded',
    url: '/yoga/console/vueneClassroom/delete'
  },
  checkClassroomName: { // 校验教室名称是否重复
    method: 'post',
    url: '/yoga/console/vueneClassroom/chcekClassroomName'
  },
  classroomDetail: { // 教室详情
    method: 'postUrlencoded',
    url: '/yoga/console/vueneClassroom/detail'
  },
  /******************** 物料管理 ********************/
  pictureList: { // 照片墙列表
    method: 'post',
    url: '/yoga/console/showPicture/list'
  },
  uploadPicture: { // 上传照片
    method: 'post',
    url: '/yoga/console/showPicture/create'
  },
  deletePicture: { // 删除照片
    method: 'postUrlencoded',
    url: '/yoga/console/showPicture/delete'
  },
  checkPicName: { // 校验照片名称是否重复
    method: 'postUrlencoded',
    url: '/yoga/console/showPicture/chcekPicName'
  },
  videoList: { // 视频课程列表
    method: 'post',
    url: '/yoga/console/showVideo/list'
  },
  uploadVideo: { // 上传视频
    method: 'post',
    url: '/yoga/console/showVideo/create'
  },
  deleteVideo: { // 删除视频
    method: 'postUrlencoded',
    url: '/yoga/console/showVideo/delete'
  },
  checkVideoName: { // 校验视频名称是否重复
    method: 'postUrlencoded',
    url: '/yoga/console/showVideo/chcekViName'
  },
  incrShowTimes: { // 增加视频播放次数
    method: 'postUrlencoded',
    url: '/yoga/console/showVideo/incrShowTimes'
  },
  /******************** 系统设置 ********************/
  sysRoleList: { // 角色列表
    method: 'post',
    url: '/yoga/console/sysRole/list'
  },
  createSysRole: { // 创建角色
    method: 'postUrlencoded',
    url: '/yoga/console/sysRole/create'
  },
  sysUserList: { // 账号列表
    method: 'post',
    url: '/yoga/console/sysUser/list'
  },
  createSysUser: { // 新增或编辑账号
    method: 'post',
    url: '/yoga/console/sysUser/create'
  },
  checkUserName: { // 校验账号名称是否重复
    method: 'post',
    url: '/yoga/console/sysUser/checkUserName'
  },
  sysUserDetail: { // 账号详情
    method: 'postUrlencoded',
    url: '/yoga/console/sysUser/detail'
  },
  disableSysUser: { // 禁用/启用
    method: 'post',
    url: '/yoga/console/sysUser/disable'
  },
  updatePassword: { // 修改密码
    method: 'post',
    url: '/yoga/console/sysUser/updatePassword'
  },
  userHeadList: { // 头像列表
    method: 'post',
    url: '/yoga/console/userHead/list'
  },
  createUserHead: { // 新增头像
    method: 'postUrlencoded',
    url: '/yoga/console/userHead/create'
  },
  timeSlotList: { // 时间段列表
    method: 'post',
    url: '/yoga/console/timeSlot/list'
  },
  createTimeSlot: { // 新增时间段
    method: 'post',
    url: '/yoga/console/timeSlot/create'
  },
  deleteTimeSlot: { // 删除时间段
    method: 'postUrlencoded',
    url: '/yoga/console/timeSlot/delete'
  },
}

export default api
