import * as React from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import BasicLayout from '../layout/basic_layout'
import BlackLayout from '../layout/black_layout'
import Home from '../views/home'
import Login from '../views/login'
import ClassList from '../views/class_manage/class_list'
import AppointmentList from '../views/class_manage/appointment_list'
import MemberList from '../views/member_manage/member_list'
import OrderList from '../views/member_manage/order_list'
import CoachList from '../views/venue_manage/coach_list'
import VenueList from '../views/venue_manage/venue_list'
import ClassroomList from '../views/venue_manage/classroom_list'
import PhotoManage from '../views/material_manage/photo_manage'
import VideoManage from '../views/material_manage/video_manage'
import RoleList from '../views/system_setting/role_list'
import AccountManage from '../views/system_setting/account_manage'
import AvatarList from '../views/system_setting/avatar_list'
import TimeList from '../views/system_setting/time_list'

const NotFound: React.FC = () => {
  return (
    <div>该页面不存在</div>
  )
}

const router: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BasicLayout />}>
          <Route index element={<Navigate to="/home" />} />
          <Route path="home" element={<Home />} />
          <Route path="class-manage/" element={<BlackLayout />}>
            <Route index element={<Navigate to="/class-manage/class-list" />} />
            <Route path="class-list" element={<ClassList />} />
            <Route path="appointment-list" element={<AppointmentList />} />
          </Route>
          <Route path="member-manage/" element={<BlackLayout />}>
            <Route index element={<Navigate to="/member-manage/member-list" />} />
            <Route path="member-list" element={<MemberList />} />
            <Route path="order-list" element={<OrderList />} />
          </Route>
          <Route path="venue-manage/" element={<BlackLayout />}>
            <Route index element={<Navigate to="/venue-manage/coach-list" />} />
            <Route path="coach-list" element={<CoachList />} />
            <Route path="venue-list" element={<VenueList />} />
            <Route path="classroom-list" element={<ClassroomList />} />
          </Route>
          <Route path="material-manage/" element={<BlackLayout />}>
            <Route index element={<Navigate to="/material-manage/photo-manage" />} />
            <Route path="photo-manage" element={<PhotoManage />} />
            <Route path="video-manage" element={<VideoManage />} />
          </Route>
          <Route path="system-setting/" element={<BlackLayout />}>
            <Route index element={<Navigate to="/system-setting/role-list" />} />
            <Route path="role-list" element={<RoleList />} />
            <Route path="account-manage" element={<AccountManage />} />
            <Route path="avatar-list" element={<AvatarList />} />
            <Route path="time-list" element={<TimeList />} />
          </Route>
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default router