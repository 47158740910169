// 课程预约状态
export const reserveStatusMap: any = {
  '1': { text: '已预约', color: 'green' },
  '2': { text: '已取消', color: 'red' },
}

export const weekDayMap:any = {
  'Mon': '周一',
  'Tues': '周二',
  'Wed': '周三',
  'Thurs': '周四',
  'Fri': '周五',
  'Sat': '周六',
  'Sun': '周日',
}

// 操作类型
export const operateTypeMap: any = {
  '1': '开卡',
  '2': '续费',
}

// 课程次数操作类型
export const numOperateTypeMap: any = {
  '1': '增加',
  '2': '扣减',
}
export const numOperateTypeList: any = [
  { label: '增加', value: '1' },
  { label: '扣减', value: '2' },
]

// 会员性别
export const customerSexList: any = [
  { label: '男', value: '1' },
  { label: '女', value: '2' },
]

// 会员卡类型
export const cardTypeMap: any = {
  '1': '次数卡',
  '2': '期限卡',
  '3': '体验卡',
}
export const cardTypeList: Array<any> = [
  { label: '次数卡', value: '1' },
  { label: '期限卡', value: '2' },
  { label: '体验卡', value: '3' },
]

// 课程类型
export const courseTypeMap: any = {
  '1': '小班课',
  '2': '私教课',
}
export const courseTypeList: Array<any> = [
  { label: '小班课', value: '1' },
  { label: '私教课', value: '2' },
]

// 课程状态
export const courseStatusMap: any = {
  '1': { text: '正常', color: 'green' },
  '2': { text: '已过期', color: 'red' },
}
export const courseStatusList: Array<any> = [
  { label: '正常', value: '1' },
  { label: '已过期', value: '2' },
]

// 课程难度
export const courseDifficultyList: Array<any> = [
  { label: '一颗星', value: '1' },
  { label: '两颗星', value: '2' },
  { label: '三颗星', value: '3' },
  { label: '四颗星', value: '4' },
  { label: '五颗星', value: '5' },
]

// 教练列表-性别
export const coachSexMap: any = {
  '1': { text: '男' },
  '2': { text: '女' },
}

// 账号状态
export const accountStatusMap: any = {
  '0': { text: '正常', color: 'green' },
  '1': { text: '禁用', color: 'red' },
}

// 视频类型
export const videoTypeList: Array<any> = [
  { label: '精品视频', value: '1' },
  { label: '推荐专栏', value: '2' },
]