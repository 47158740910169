// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Modal, Form, TimePicker, InputNumber } from 'antd'
import { API } from '../../../../api'
import { getHhMm } from '../../../../utils'
import '../index.scss'

const dayjs = require('dayjs')

const Page: React.FC<any> = ({visible, modalChild, editObj}) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...editObj,
        startTime: editObj.timeSlot ? dayjs(editObj.timeSlot.split('-')[0], 'HH:mm') : undefined,
        endTime: editObj.timeSlot ? dayjs(editObj.timeSlot.split('-')[1], 'HH:mm') : undefined,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, editObj])

  const handleStartTime = (time: any) => {
    calculateDuration()
  }

  const handleEndTime = (time: any) => {
    calculateDuration()
  }

  const calculateDuration = () => {
    const {startTime, endTime} = form.getFieldsValue()
    const duration = startTime ? dayjs(endTime).diff(dayjs(startTime), 'minute') : 0;
    form.setFieldsValue({
      timeSecond: duration || 0
    })
  }

  const submitForm = async (values: any) => {
    setConfirmLoading(true)
    const res: any = await API.createTimeSlot({
      id: editObj.id || undefined,
      timeSlot: getHhMm(values.startTime['$d']) + '-' + getHhMm(values.endTime['$d']),
      timeSecond: values.timeSecond
    })
    setConfirmLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      Message.success('操作成功')
      modalChild(true)
      handleCancel()
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      submitForm(values)
    } catch (errorInfo) {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmLoading(false)
    modalChild(false)
  }
  
  return (
    <Modal 
      title={editObj.operateType === 'edit' ? '编辑时间段' : '新增时间段'} 
      width={360} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-account-modal"
    >
      <Form preserve={false} form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Form.Item 
          label="开始时间" 
          name="startTime"
          rules={[{ required: true, message: '请选择开始时间' }]}
        >
          <TimePicker placeholder="请选择开始时间" format={'HH:mm'} onChange={handleStartTime} showNow={false} style={{width: '180px'}} />
        </Form.Item>
        <Form.Item 
          label="结束时间" 
          name="endTime"
          rules={[{ required: true, message: '请选择结束时间' }]}
        >
          <TimePicker placeholder="请选择结束时间" format={'HH:mm'} onChange={handleEndTime} showNow={false} style={{width: '180px'}} />
        </Form.Item>
        <Form.Item 
          label="时长" 
          name="timeSecond"
          required
          rules={[
            {
              validator: (rule, value) => {
                if (value) {
                  if (value === 0 || (value && value < 0)) {
                    return Promise.reject('时长必须大于0')
                  } else {
                    return Promise.resolve()
                  }
                } else {
                  return Promise.reject('请输入时长')
                }
              }
            }
          ]}
        >
          <InputNumber placeholder="请输入时长" style={{width: '180px'}} disabled />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
