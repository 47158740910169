// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input, Checkbox, message as Message } from 'antd'
import localDb from '../../utils/localDb'
import { getCookie, setCookie } from '../../utils/cookie'
import md5 from 'js-md5'
import { API } from '../../api'
import './index.scss'
const Base64 = require("js-base64").Base64

let timer: undefined = undefined

const Page: React.FC = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loginLoading, setLoginLoading] = useState<boolean>(false)
  const [hideCodeItem, setHideCodeItem] = useState<boolean>(true)
  const [countDown, setCountDown] = useState<boolean>(false)
  let [count, setCount] = useState<number>(0)
  const [rememberMe, setRememberMe] = useState<boolean>(false)

  useEffect(() => {
    if (count === 120) {
      let currentTimer: any = setInterval(() => {
        if (count === 0) {
          clearInterval(timer)
          timer = undefined
          setCountDown(false)
        }
        setCount(count--)
      }, 1000)
      timer = currentTimer
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  /*========== 通过密码登录 ==========*/
  const loginIn = async (values: any) => {
    setLoginLoading(true)
    const res: any = await API.login({
      userName: values.userName,
      pwd: Base64.encode(values.pwd)
    })
    setLoginLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      dealData(data)
    } else if (code === "400002") {
      // 验证码不能为空
      setHideCodeItem(false);
    } else {
      Message.error(message)
    }
  }

  /*========== 通过验证码获取登录 ==========*/
  const loginInByCode = async (values: any) => {
    setLoginLoading(true)
    const res: any = await API.login({
      userName: values.userName,
      pwd: Base64.encode(values.pwd),
      code: values.code,
      xtime: +new Date() + ""
    })
    setLoginLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      dealData(data)
    } else {
      Message.error(message)
    }
  }
  
  const handleLogin = async () => {
    try {
      const values = await form.validateFields()
      if (hideCodeItem) {
        // loginIn(values);
        // 验证码不能为空
        setHideCodeItem(false);
      } else {
        loginInByCode(values);
      }
    } catch (errorInfo) {
      setLoginLoading(false)
    }
  }

  const dealData = (data: any) => {
    const {userName, pwd} = form.getFieldsValue()
    
    let token = data.xtoken
    if (token) { // 处理头部信息
      localDb.set("user", data.userInfo)
      let time = +new Date() + ''
      // x-key生成规则：把token里面的0去掉，然后+time，md5加密
      let key = md5(token.replace(/0/gi, '') + time)
      setCookie("x-token", token);
      setCookie("x-key", key);
      setCookie("x-time", time);
      navigate('/home')
    }
  }

  const getCode = async () => {
    const {userName, pwd} = form.getFieldsValue()
    if (userName && pwd) {
      const res: any = await API.sendCode({
        userName: userName,
        pwd: Base64.encode(pwd)
      })
      if (!res) return false
      const { code, message, data } = res
      if (code === '000000') {
        countDownFunc()
      } else {
        Message.error(message)
      }
    } else {
      Message.warning("请先输入账号和密码")
    }
  }

  const countDownFunc = () => {
    if (timer) {
      clearInterval(timer);
      timer = undefined
    }
    setCountDown(true)
    setCount(120)
  }

  const handleRemember = (e:any) => {
    setRememberMe(e.target.checked)
  }

  return (
    <div className='login-page'>
      <div className='login-page-section'>
        <div className='login-bg'>
          <img className='login-img' src={require('../../assets/images/login_bg.png')} alt="背景图" />
          <p className='login-bg-text login-bg-text-big'>Lorem Ipsum is simply</p>
          <p className='login-bg-text login-bg-text-small'>Lorem Ipsum is simply</p>
        </div>
        <div className='login-form'>
          <div className='logo-wrap'>
            <img src={require('../../assets/images/logo.png')} alt="logo" width={40} />
          </div>
          <div className='form-wrap'>
            <div className='title-wrap'>
              <p>欢迎登陆</p>
              <p className='title-text'>Ten+Yoga</p>
            </div>
            <Form form={form} layout="vertical">
              <Form.Item 
                label="账号" 
                name="userName"
                rules={[{ required: true, message: '请输入你的账号/手机号' }]}
              >
                <Input placeholder="请输入你的账号/手机号" className='form-input' />
              </Form.Item>
              <Form.Item 
                label="密码" 
                name="pwd"
                rules={[
                  { required: true, message: '请输入你的账号密码' }
                ]}
              >
                <Input.Password placeholder="请输入你的账号密码" className='form-input' />
              </Form.Item>
              {!hideCodeItem && <Form.Item
                label="验证码" 
                name="code"
                rules={[{ required: true, message: '请输入你的验证码' }]}
              >
                <div className="get-code-wrap">
                  <Input
                    className="code-btn get-code-input"
                    placeholder="请输入你的验证码"
                  >
                  </Input>
                  {
                    countDown ?
                    <Button className='code-btn get-code-btn' disabled={true}>{count}s</Button> :
                    <Button className='code-btn get-code-btn' onClick={getCode}>获取验证码</Button>
                  }
                </div>
              </Form.Item>}
              <Form.Item>
                <div className='form-operate'>
                  <div className='form-operate-left'>
                    {/* <Checkbox onChange={handleRemember} checked={rememberMe}>记住我</Checkbox> */}
                  </div>
                  <div className='form-operate-right'>
                    {/* <p className='forget-text'>忘记密码 ?</p> */}
                  </div>
                </div>
              </Form.Item>
              <Form.Item>
                <div className='btn-wrap'>
                  <Button className='form-btn' loading={loginLoading}  onClick={handleLogin}>登录</Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page
