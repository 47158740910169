// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Modal, Form, Input, Select, Radio, DatePicker, Button, Upload  } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker';
import { PlusOutlined } from '@ant-design/icons'
import { API } from '../../../../api'
import '../index.scss'
import SelectAvatar from './select_avatar'
import { fileResourcePath } from '../../../../utils/index'

const dayjs = require('dayjs')

const Page: React.FC<any> = ({visible, modalChild, editObj}) => {
  const { message: Message } = App.useApp()
  const { TextArea } = Input;
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [venueList, setVenueList] = useState<[]>([])
  const [avatarVisible, setAvatarVisible] = useState<Boolean>(false)
  const [avatarObj, setAvatarObj] = useState<string>('')

  const [pic1, setPic1] = useState<string>('')
  const [pic2, setPic2] = useState<string>('')
  const [pic3, setPic3] = useState<string>('')

  useEffect(() => {
    if (visible) {
      getVenueList()
      form.setFieldsValue({
        ...editObj,
        userBirthday: editObj.operateType === 'edit' && editObj.userBirthday ? dayjs(editObj.userBirthday, 'YYYY-MM-DD') : undefined
      })

      setAvatarObj(editObj.userHeadPic)
      setPic1(editObj.pic1)
      setPic2(editObj.pic2)
      setPic2(editObj.pic3)
    } else {
      setAvatarObj('')
      setPic1('')
      setPic2('')
      setPic2('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, editObj])

  const getVenueList = async () => {
    const res: any = await API.venueList({
      page: 1,
      size: 100,
    })
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setVenueList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const handleSelectVenue = (value: string) => {
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // 不能选择当天以及之后的日期
    const today = dayjs().startOf('day');
    const currentDate = dayjs(current).startOf('day');
    return currentDate.isAfter(today) || currentDate.isSame(today);
  };

  const validatorCheckName = async (rule: any, value: any, callback: any) => {
    if (value) {
      const res: any = await API.checkCoachName({
        id: editObj.id || undefined,
        userName: value
      })
      if (!res) return false
      const { code, data, message } = res
      if (code === '000000') {
        if (data) {
          return Promise.resolve()
        } else {
          return Promise.reject('教练名称重复')
        }
      } else {
        return Promise.reject(message)
      }
    } else {
      return Promise.reject('请输入教练姓名')
    }
  }

  const beforeUpload = (type: string, file: any) => {
    const isImg = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 <= 2;

    if(isImg && isLt2M) {
      handleUpload(type, file)
    } else {
      Message.error('仅支持jpg，png，jpeg格式,图片大小不大于2M!');
    }
    return false;
  }

  const handleUpload = async (type: string, file: any) => {
    const res: any = await API.normalUpload({
      file: file
    })
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      switch (type) {
        case 'pic1':
          setPic1(data)
          break;
        case 'pic2':
          setPic2(data)
          break;
        case 'pic3':
            setPic3(data)
            break;
        default:
          break;
      }
    } else {
      Message.error(message)
    }
  }

  const submitForm = async (values: any) => {
    setConfirmLoading(true)
    const res: any = await API.createCoach({
      id: editObj.id || undefined,
      venueId: values.venueId,
      userName: values.userName,
      userPhone: values.userPhone,
      userSex: values.userSex,
      userBirthday: dayjs(values.userBirthday).format('YYYY-MM-DD'),
      userHeadPic: values.userHeadPic,
      pic1: pic1,
      pic2: pic2,
      pic3: pic3,
      userDesc: values.userDesc,
    })
    setConfirmLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      Message.success('操作成功')
      modalChild(true)
      handleCancel()
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      submitForm(values)
    } catch (errorInfo) {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmLoading(false)
    modalChild(false)
  }

  const selectAvatar = () => {
    setAvatarVisible(true)
  }

  const avatarChild = (isOk: Boolean, params: any) => {
    setAvatarVisible(false)
    if (isOk) {
      setAvatarObj(params)
      form.setFieldsValue({
        userHeadPic: params
      })
    }
  }
  
  return (
    <Modal 
      title={editObj.operateType === 'edit' ? '编辑教练' : '新增教练'} 
      width={648} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-coach-modal"
    >
      <Form preserve={false} form={form} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
        <div className='coach-form-section'>
          <div className='coach-form-section-left'>
            <Form.Item 
              label="场馆" 
              name="venueId"
              rules={[{ required: true, message: '请选择场馆' }]}
            >
              <Select
                allowClear
                placeholder="请选择场馆"
                onChange={(value) => handleSelectVenue(value)}
                options={venueList}
                fieldNames={{
                  label: 'venueName',
                  value: 'id'
                }}
                style={{width: '180px'}}
              />
            </Form.Item>
            <Form.Item 
              label="教练电话" 
              name="userPhone"
              rules={[
                { required: true, message: '请输入教练电话' },
                { pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入正确的电话号码' },
              ]}
            >
              <Input placeholder="请输入教练电话" style={{width: '180px'}} />
            </Form.Item>
            <Form.Item 
              label="教练生日" 
              name="userBirthday"
              rules={[{ required: true, message: '请选择教练生日' }]}
            >
              <DatePicker disabledDate={disabledDate} showToday={false} style={{width: '180px'}} />
            </Form.Item>
          </div>
          <div className='coach-form-section-right'>
            <Form.Item 
              label="教练姓名" 
              name="userName"
              required
              rules={[{ validator: validatorCheckName }]}
              validateTrigger="onBlur"
            >
              <Input placeholder="请输入教练姓名" style={{width: '180px'}} />
            </Form.Item>
            <Form.Item 
              label="教练性别" 
              name="userSex"
              rules={[{ required: true, message: '请选择教练性别' }]}
            >
              <Radio.Group>
                <Radio value={1}>男</Radio>
                <Radio value={2}>女</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item 
              label="教练头像" 
              name="userHeadPic"
              rules={[{ required: true, message: '请选择教练头像' }]}
            >
              <div className='select-btn-wrap'>
                <Button type="primary" className='select-btn' onClick={selectAvatar}>选择头像</Button>
                <span className='name'>{form.getFieldValue('userHeadPic')}</span>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className='coach-form-row'>
          <Form.Item 
            label="教练照片" 
            name="pic1"
            rules={[{ required: false, message: '请上传教练照片' }]}
          >
            <div className='upload-row'>
              <Upload
                name="file"
                listType="picture-card"
                className="coach-picture-uploader"
                showUploadList={false}
                fileList={[]}
                accept="image/jpg, image/png, image/jpeg"
                beforeUpload={(file) => beforeUpload('pic1', file)}
              >
                {pic1 ? <img src={fileResourcePath(pic1)} alt="图片" style={{ width: '100%' }} /> : (
                  <div className='click-wrap'>
                    <PlusOutlined />
                  </div>
                )}
              </Upload>
              <Upload
                name="file"
                listType="picture-card"
                className="coach-picture-uploader"
                showUploadList={false}
                fileList={[]}
                accept="image/jpg, image/png, image/jpeg"
                beforeUpload={(file) => beforeUpload('pic2', file)}
              >
                {pic2 ? <img src={fileResourcePath(pic2)} alt="图片" style={{ width: '100%' }} /> : (
                  <div className='click-wrap'>
                    <PlusOutlined />
                  </div>
                )}
              </Upload>
              <Upload
                name="file"
                listType="picture-card"
                className="coach-picture-uploader"
                showUploadList={false}
                fileList={[]}
                accept="image/jpg, image/png, image/jpeg"
                beforeUpload={(file) => beforeUpload('pic3', file)}
              >
                {pic3 ? <img src={fileResourcePath(pic3)} alt="图片" style={{ width: '100%' }} /> : (
                  <div className='click-wrap'>
                    <PlusOutlined />
                  </div>
                )}
              </Upload>
            </div>
          </Form.Item>
        </div>
        <div className='coach-form-row textarea-row'>
          <Form.Item 
            label="教练描述" 
            name="userDesc"
            rules={[{ required: false, message: '请输入教练描述' }]}
            labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}
          >
            <TextArea placeholder='请输入教练描述' style={{ width: 600 }} />
          </Form.Item>
        </div>
      </Form>
      <SelectAvatar visible={avatarVisible} avatarChild={avatarChild} avatarObj={avatarObj} />
    </Modal>
  )
}

export default Page
