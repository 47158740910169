// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { Modal, App, Table, DatePicker, Button } from 'antd';
import { numOperateTypeMap } from '../../../../../constants'
import { API } from '../../../../../api'
import './index.scss'

const { RangePicker } = DatePicker

interface IProps {
  visible: boolean;
  info: any;
  handleCancel: Function;
}

interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC<IProps> = ({ visible, info, handleCancel }) => {
  const { message: Message } = App.useApp()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [searchParams, setSearchParams] = useState<any>({
    date: undefined,
  });
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<any>([])

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, record: any, index: number) => {
        return index + 1
      },
      width: 100,
      fixed: 'left',
    },
    {
      title: '日期',
      dataIndex: 'createTime',
      key: 'createTime',
      ellipsis: true
    },
    {
      title: '操作类型',
      dataIndex: 'operateType',
      key: 'operateType',
      width: 130,
      render: (text: any) => numOperateTypeMap[text] || ''
    },
    {
      title: '积分',
      dataIndex: 'integralNum',
      key: 'integralNum',
      render: (text: any) => text || 0,
    },
    {
      title: '关联课程',
      dataIndex: 'courseName',
      key: 'courseName',
      ellipsis: true
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      ellipsis: true
    },
    {
      title: '创建人',
      dataIndex: 'createUserName',
      key: 'createUserName',
      ellipsis: true
    },
  ]

  const scrollX: number = columns.reduce((pre: number, curr: any) => {
    return pre + (curr.width || 150)
  }, 0)

  useEffect(() => {
    visible && getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, pageInfo])

  const getList = async () => {
    let params: any = {
      customerId: info.id,
      startDate: searchParams.date ? searchParams.date[0].format('YYYY-MM-DD')  : undefined,
      endDate: searchParams.date ? searchParams.date[1].format('YYYY-MM-DD') : undefined,
      page: pageInfo.current,
      size: pageInfo.pageSize,
    }
    setTableLoading(true)
    const res: any = await API.integralList(params)
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const handleSearch = () => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    })
  }

  const handleFilterChange = (key: string, value: any) => {
    setSearchParams({
      ...searchParams,
      [key]: value
    })
  }

  return (
    <Modal
      title="积分明细"
      open={visible}
      onOk={() => handleCancel()}
      onCancel={() => handleCancel()}
      width={800}
      destroyOnClose={true}
      wrapClassName="integral-detail-modal"
    >
      <div className='yg-page-filter' style={{ padding: '0px 0px 20px 0px' }}>
        <div className='yg-page-filter-item'>
          <RangePicker
            value={searchParams.date}
            onChange={(date) => handleFilterChange('date', date)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <Button className='yg-ghost-btn' onClick={handleSearch}>查询</Button>
        </div>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        className='yg-small-table'
        loading={tableLoading}
        scroll={{ x: scrollX }}
        pagination={{
          ...pageInfo,
          total,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
          onChange: handlePageChange,
        }}
      />
    </Modal>
  )
}

export default Page
