// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Modal, Form, Upload } from 'antd'
import { API } from '../../../../api'
import '../index.scss'
import { fileResourcePath } from '../../../../utils/index'

const Page: React.FC<any> = ({visible, modalChild}) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [filePath, setFilePath] = useState<string>('')

  useEffect(() => {
    if (visible) {
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const beforeUpload = (file: any) => {
    const isImg = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 <= 2;

    if(isImg && isLt2M) {
      handleUpload(file)
    } else {
      Message.error('仅支持jpg，png，jpeg格式,图片大小不大于2M!');
    }
    return false;
  }

  const handleUpload = async (file: any) => {
    const res: any = await API.normalUpload({
      file: file
    })
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setFilePath(data)
    } else {
      Message.error(message)
    }
    
  }

  const submitForm = async () => {
    setConfirmLoading(true)
    const res: any = await API.createUserHead({
      filePath: filePath
    })
    setConfirmLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      Message.success('操作成功')
      modalChild(true)
      handleCancel()
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      // console.log('Success:', values)
      if (filePath) {
        submitForm()
      } else {
        setConfirmLoading(false)
      }
    } catch (errorInfo) {
      // console.log('Failed:', errorInfo)
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setFilePath('')
    setConfirmLoading(false)
    modalChild(false)
  }
  
  return (
    <Modal 
      title="新增头像" 
      width={500} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-avatar-modal"
    >
      <Form preserve={false} form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 17 }} className="add-avatar-form">
        <Form.Item 
          label="选择" 
          name="picture"
          rules={[{ required: true, message: '请选择图片' }]}
        >
          <div>
            <Upload
              name="file"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              fileList={[]}
              accept="image/jpg, image/png, image/jpeg"
              beforeUpload={beforeUpload}
            >
              {filePath ? <img src={fileResourcePath(filePath)} alt="图片" style={{ width: '100%' }} /> : (
                <div className='click-wrap'>
                  <img src={require('../../../../assets/images/upload.png')} alt="上传" width={24} />
                  <p className='click-text'>点击选择图片</p>
                </div>
              )}
            </Upload>
            <div className='img-tips'>
              <p>* 图片大小不大于2M；</p>
              <p>* 支持jpg，png，Jpeg格式文件；</p>
            </div>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
