import React, { useState, useEffect } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Spin, App } from 'antd'
import dayjs from 'dayjs'
import { API } from '../../api'
import './index.scss'

interface IProps {
  onSelect: Function;
  initDate?: any;
  mini?: boolean;
  value?: any;
}

const dayMap: any = {
  '0': '周日',
  '1': '周一',
  '2': '周二',
  '3': '周三',
  '4': '周四',
  '5': '周五',
  '6': '周六',
}

const colorArr = ['#FFB388', '#96ADFF', '#F7B73D']

const Page: React.FC<IProps> = ({ mini, initDate, onSelect, value }) => {
  const { message: Message } = App.useApp()
  const [data, setData] = useState<any>([])
  const [currentDate, setCurrentDate] = useState<any>(dayjs())
  const [dateList, setDateList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [timeSlotList, setTimeSlotList] = useState<any>([])

  useEffect(() => {
    getData()
    getTimeSlot()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    initDate && setCurrentDate(initDate)
  }, [initDate])

  useEffect(() => {
    const newDateList = new Array(7).fill(currentDate).map((ele: any, index: number) => {
      let currDay: any = ele.add(index, 'day')
      return {
        dayEle: currDay,
        weekDay: dayMap[currDay.day()],
        dateStr: currDay.format('MM月DD日'),
        dateKey: currDay.format('YYYYMMDD'),
      }
    })
    setDateList([{}, ...newDateList])
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate])

  const getData = async () => {
    setLoading(true)
    const res: any = await API.calendarData({
      courseStartDate: currentDate.format('YYYY-MM-DD'),
      courseEndDate: currentDate.add(6, 'day').format('YYYY-MM-DD'),
    })
    setLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      dealData(data || [])
    } else {
      Message.error(message)
    }
  }

  const getTimeSlot = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.timeSlotList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      let newList: any[] = [];
      (data?.list || []).forEach((ele: any) => {
        let key = ele.timeSlot?.split('-')[0] || ''
        if (!newList.includes(key)) {
          newList.push(key)
        }
      })
      setTimeSlotList(newList)
    } else {
      Message.error(message)
    }
  }

  const dealData = (data: any[]) => {
    let newData = data.reduce((pre: any, curr: any, index: number) => {
      let startTime = curr.courseSlot?.split('-')[0] || ''
      let key = dayjs(curr.courseDate).format('YYYYMMDD') + '_' + startTime
      return {
        ...pre,
        [key]: [...(pre[key] || []), {...curr, index}]
      }
    }, {})
    setData(newData)
  }
  
  const handleSwapperClick = (type: string) => {
    let newDate: any = currentDate
    switch (type) {
      case 'pre': {
        if (dayjs().isSame(newDate, 'days')) {
          return false
        }
        newDate = currentDate.subtract(7, 'day')
        if (newDate.isBefore(dayjs())) {
          newDate = dayjs()
        }
        break
      }
      case 'next': {
        newDate = currentDate.add(7, 'day')
        break
      }
      case 'today': {
        newDate = dayjs()
        break
      }
      default:
        break
    }
    setCurrentDate(newDate)
  }

  const renderClassCard = (detail: any) => {
    const currColor = colorArr[detail.index % 3]
    return (<div className='class-card' style={{ background: (mini && detail.courseStatus + '' === '2') ? '#bfbfbf' : currColor }}>
      <div className='class-title' title={detail.courseName}>{detail.courseName}</div>
      <div className='class-coach' title={detail.userName}>{detail.userName}</div>
      <div className='class-time'>{detail.courseSlot}</div>
      {
        mini ?
        <>
          {
            detail.courseStatus + '' === '2' ?
            <div className='view-btn' style={{ color: '#bfbfbf', cursor: 'not-allowed' }}>已过期</div> :
            detail.id === value?.id ?
            <div className='view-btn' style={{ color: currColor, cursor: 'not-allowed' }}>已选择</div> :
            <div className='view-btn' onClick={() => onSelect(detail)} style={{ color: currColor }}>预约</div>
          }
        </> :
        <div className='view-btn' onClick={() => onSelect(detail)} style={{ color: currColor }}>详情</div>
      }
    </div>)
  }
  return (
    <div className={['class-calendar-comp', mini ? 'class-calendar-comp-mini' : ''].join(' ')}>
      <div className='date-swapper-wrapper'>
        <div className='year-month-box'>{currentDate.format('YYYY年M月')}</div>
        <div className='swapper-btn-box'>
          <span className={['pre-btn', dayjs().isSame(currentDate, 'days') ? 'disabled' : ''].join(' ')} onClick={() => handleSwapperClick('pre')}>
            <LeftOutlined />
          </span>
          <span className='center-line'>
          </span>
          <span className='next-btn' onClick={() => handleSwapperClick('next')}>
            <RightOutlined />
          </span>
        </div>
        <div className='today-btn' onClick={() => handleSwapperClick('today')}>今天</div>
      </div>
      <div className='calendar-table-wrapper'>
      {loading && <Spin spinning={true}></Spin>}
      <div className='calendar-table-with-head-wrapper yg-scroll-bar'>
        <table className='calendar-table-with-head'>
          <thead className='calendar-head'>
            <tr className='calendar-row'>
              {dateList.map((dateEle: any, index: number) => {
                if (index === 0) {
                  return <th key={'start' + index} className='calendar-col'>
                    <span className='time-cell'>时间</span>
                  </th>
                }
                return <th key={'date' + index} className='calendar-col'>
                  <div className='date-cell'>
                    <span className='date-str'>{dateEle.dateStr}</span>
                    <span className='date-week'>{dateEle.weekDay}</span>
                  </div>
                </th>
              })}
            </tr>
          </thead>
        </table>
      </div>
      <div className='calendar-table-with-body-wrapper yg-scroll-bar'>
      <table className='calendar-table-with-body'>
        <tbody className='calendar-body'>
          {timeSlotList.map((timeEle: any, rowIndex: number) => {
            return <tr className='calendar-row' key={rowIndex}>
              {dateList.map((dateEle: any, colIndex: number) => {
                if (colIndex === 0) {
                  return <td key={rowIndex + 'time' + colIndex} className='calendar-col'>
                    <span className='time-cell'>{timeEle}</span>
                  </td>
                }
                let classArr = data[dateEle.dateKey + '_' + timeEle] || []
                return <td key={rowIndex + 'class' + colIndex} className='calendar-col'>
                  {
                    classArr.length > 0 ? 
                    <div className='class-cell'>
                      {classArr.map((classEle: any, classIndex: number) => (<div className='class-item' key={'class-item' + classIndex}>
                        {renderClassCard(classEle)}
                      </div>))}
                    </div> :
                    <div className='empty-box'></div>
                  }
                </td>
              })}
            </tr>
          })}
        </tbody>
      </table>
      </div>
      </div>
    </div>
  )
}

export default Page
