import React, { useState, useEffect } from 'react'
import { Modal, App, Form, Input, Select, InputNumber, Rate, DatePicker, Radio } from 'antd'
import { courseTypeList } from '../../../../../constants'
import { API } from '../../../../../api'
import './index.scss'
import dayjs from 'dayjs'

const { TextArea } = Input

interface IProps {
  visible: boolean;
  handleClose: Function;
}

const Page: React.FC<IProps> = ({ visible, handleClose }) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()
  const [timeSlotList, setTimeSlotList] = useState<any>([])
  const [timeSecondMap, setTimeSecondMap] = useState<any>({})
  const [classroomList, setClassroomList] = useState<any>([])
  const [coachList, setCoachList] = useState<any>([])
  const [hideRepeatType, setHideRepeatType] = useState<boolean>(true)

  const formItemLayoutNormal = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
  }

  const formItemLayoutOneLine = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  }

  const repeatTypeList: any[] = [
    { label: '按周', value: '2' },
    { label: '按月', value: '3' },
  ]

  const weekDayMap: any = {
    '0': 'Sun',
    '1': 'Mon',
    '2': 'Tues',
    '3': 'Wed',
    '4': 'Thurs',
    '5': 'Fri',
    '6': 'Sat',
  }

  useEffect(() => {
    let newObj = timeSlotList.reduce((pre: any, curr: any) => {
      return {
        ...pre,
        [curr.timeSlot]: curr.timeSecond
      }
    }, {})
    setTimeSecondMap(newObj)
  }, [timeSlotList])

  useEffect(() => {
    if (visible) {
      getTimeSlot()
      getCoachList()
      getClassroomList()
      init()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const init = () => {
    form.resetFields()
    form.setFieldsValue({
      courseRepetNum: 1,
      courseDate: dayjs(),
      courseName: undefined,
      courseType: undefined,
      courseClassroomId: undefined,
      courseVenueUserId: undefined,
      courseCanReserve: undefined,
      courseDifficulty: undefined,
      courseSlot: undefined,
      courseDesc: undefined,
    })
  }

  const getTimeSlot = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.timeSlotList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setTimeSlotList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const getCoachList = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.coachList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setCoachList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const getClassroomList = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.classroomList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setClassroomList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const handleSubmit = async () => {
    try {
      const values: any = await form.validateFields();
      let params: any = {
        ...values,
        courseWeekDay: weekDayMap[values.courseDate.day()],
        courseDate: values.courseDate.format('YYYY-MM-DD'),
        courseTimes: timeSecondMap[values.courseSlot],
        courseRepetType: values.courseRepetType || '1' // 1-不重复 2-按周 3-按月
      }
      addCourse(params)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }

  const addCourse = async (params: any) => {
    const res: any = await API.createCourse(params)
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success('新增成功')
      handleClose(true)
    } else {
      Message.error(message)
    }
  } 

  const handleRepeatNumChange = (num: any) => {
    let newVal = num <= 1
    if (hideRepeatType === newVal) {
      return false
    }
    setHideRepeatType(newVal)
  }

  return (
    <Modal
      title="新增课程"
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => handleClose(false)}
      width={650}
      wrapClassName="add-class-modal"
    >
      <Form form={form}>
        <Form.Item
          className='one-line-item'
          {...formItemLayoutOneLine}
          name="courseName"
          label="课程名称"
          required
          rules={[{ required: true, message: '请输入课程名称' }]}
        >
          <Input placeholder="请输入课程名称" />
        </Form.Item>
        <div className='gray-bg'>
          <Form.Item
            {...formItemLayoutNormal}
            name="courseType"
            label="课程类型"
            rules={[{ required: true, message: '请选择课程类型' }]}
          >
            <Select
              placeholder="请选择课程类型"
              options={courseTypeList}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayoutNormal}
            name="courseClassroomId"
            label="课程教室"
            rules={[{ required: true, message: '请选择课程教室' }]}
          >
            <Select
              placeholder="请选择课程教室"
              options={classroomList.map((ele: any) => ({
                label: ele.classroomName, value: ele.id
              }))}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayoutNormal}
            name="courseVenueUserId"
            label="课程教练"
            rules={[{ required: true, message: '请选择课程教练' }]}
          >
            <Select
              placeholder="请选择课程教练"
              options={coachList.map((ele: any) => ({
                label: ele.userName, value: ele.id
              }))}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayoutNormal}
            name="courseCanReserve"
            label="可预约数"
            rules={[{ required: true, message: '请输入可预约数' }]}
          >
            <InputNumber precision={0} style={{ width: '100%' }} min={1} placeholder="请输入可预约数" />
          </Form.Item>
          <Form.Item
            {...formItemLayoutNormal}
            name="courseDifficulty"
            label="课程难度"
            className='without-margin-bottom'
            rules={[{ required: true, message: '请选择课程难度' }]}
          >
            <Rate />
          </Form.Item>
        </div>
        <div className='gray-bg'>
          <Form.Item
            {...formItemLayoutNormal}
            name="courseDate"
            label="课程日期"
            rules={[{ required: true, message: '请选择课程日期' }]}
          >
            <DatePicker  style={{ width: '100%' }} placeholder="请选择课程日期" />
          </Form.Item>
          <Form.Item
            {...formItemLayoutNormal}
            name="courseRepetNum"
            label="课程周期"
            rules={[{ required: true, message: '请输入课程周期' }]}
          >
            <InputNumber onChange={handleRepeatNumChange} precision={0} style={{ width: '100%' }} min={1} placeholder="请输入课程周期" />
          </Form.Item>
          <Form.Item
            {...formItemLayoutNormal}
            name="courseSlot"
            label="时间段"
            className='without-margin-bottom'
            rules={[{ required: true, message: '请选择时间段' }]}
          >
            <Select
              placeholder="请选择时间段"
              options={timeSlotList.map((ele: any) => ({
                label: `${ele.timeSlot}(${ele.timeSecond}分钟)`, value: ele.timeSlot
              }))}
            />
          </Form.Item>
          {!hideRepeatType && <Form.Item
            {...formItemLayoutNormal}
            name="courseRepetType"
            label="周期类型"
            className='without-margin-bottom'
            rules={[{ required: true, message: '请选择周期类型' }]}
          >
            <Radio.Group options={repeatTypeList} />
          </Form.Item>}
        </div>
        <Form.Item
          className='one-line-item without-margin-bottom'
          {...formItemLayoutOneLine}
          name="courseDesc"
          label="课程描述"
          rules={[{ required: false, message: '请输入课程描述' }]}
        >
          <TextArea rows={4} placeholder="请输入课程描述" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
