// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Modal, Form, Upload, Input } from 'antd'
import { API } from '../../../../api'
import { fileResourcePath } from '../../../../utils/index'
import '../index.scss'

const Page: React.FC<any> = ({visible, modalChild}) => {
  const { message: Message } = App.useApp()
  const { TextArea } = Input;
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [filePath, setFilePath] = useState<string>('')

  useEffect(() => {
    if (visible) {
      setFilePath('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const beforeUpload = (file: any) => {
    const isImg = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 <= 2;

    if(isImg && isLt2M) {
      handleUpload(file)
    } else {
      Message.error('仅支持jpg，png，jpeg格式,图片大小不大于2M!');
    }
    return false;
  }

  const handleUpload = async (file: any) => {
    const res: any = await API.normalUpload({
      file: file
    })
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setFilePath(data)
    } else {
      Message.error(message)
    }
  }

  const validatorCheckName = async (rule: any, value: any, callback: any) => {
    if (value) {
      const res: any = await API.checkPicName({
        picName: value
      })
      if (!res) return false
      const { code, data, message } = res
      if (code === '000000') {
        if (data) {
          return Promise.resolve()
        } else {
          return Promise.reject('照片名称重复')
        }
      } else {
        return Promise.reject(message)
      }
    } else {
      return Promise.reject('请输入照片名称')
    }
  }
  
  const submitForm = async (values: any) => {
    setConfirmLoading(true)
    const res: any = await API.uploadPicture({
      picName: values.picName,	
      picFlag: values.picFlag,
      picPath: filePath,
      picDesc: values.picDesc,
    })
    setConfirmLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      Message.success('操作成功')
      modalChild(true)
      handleCancel()
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      submitForm(values)
    } catch (errorInfo) {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmLoading(false)
    modalChild(false)
    setFilePath('')
  }
  
  return (
    <Modal 
      title="上传照片" 
      width={500} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-account-modal"
    >
      <Form preserve={false} form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
        <Form.Item 
          label="照片名称" 
          name="picName"
          required
          rules={[{ validator: validatorCheckName }]}
          validateTrigger="onBlur" // 只在失去焦点时触发校验
        >
          <Input placeholder="请输入照片名称" />
        </Form.Item>
        <Form.Item 
          label="标记/分组" 
          name="picFlag"
          rules={[{ required: true, message: '请输入标记/分组' }]}
        >
          <Input placeholder="请输入标记/分组" />
        </Form.Item>
        <Form.Item 
          label="选择" 
          name="picture"
          rules={[{ required: true, message: '请选择图片' }]}
        >
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            fileList={[]}
            accept="image/jpg, image/png, image/jpeg"
            beforeUpload={beforeUpload}
          >
            {filePath ? <img src={fileResourcePath(filePath)} alt="图片" style={{ width: '100%' }} /> : (
              <div className='click-wrap'>
                <img src={require('../../../../assets/images/upload.png')} alt="上传" width={24} />
                <p className='click-text'>点击选择图片</p>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item 
          label="照片描述" 
          name="picDesc"
          rules={[{ required: false, message: '请输入照片描述' }]}
        >
          <TextArea placeholder='请输入照片描述' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
