import React, { useState, useEffect } from 'react'
import { Modal, App, Spin, Rate, Select } from 'antd'
import { API } from '../../../../../api'
import { fileResourcePath } from '../../../../../utils/index'
import classIcon from '../../../../../assets/images/class_icon.png'
import './index.scss'

interface IProps {
  visible: boolean;
  courseInfo: any;
  handleClose: Function;
}

const Page: React.FC<IProps> = ({ visible, courseInfo, handleClose }) => {
  const { message: Message } = App.useApp()
  const [loading, setLoading] = useState<boolean>(false)
  const [detailData, setDetailData] = useState<any>({})
  const [value, setValue] = React.useState<any>();
  const [coachList, setCoachList] = useState<any>([])

  useEffect(() => {
    if (visible) {
      getData()
      getCoachList()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const getCoachList = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.coachList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      let arr = (data?.list || []).map((ele: any) => ({
        label: ele.userName, value: ele.id
      }))
      setCoachList(arr)
    } else {
      Message.error(message)
    }
  }

  const getData = async () => {
    let params: any = {
      id: courseInfo.id,
    }
    setLoading(true)
    const res: any = await API.courseDetail(params)
    setLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDetailData(data || {})
      setValue(data?.courseVenueUserId)
    } else {
      Message.error(message)
    }
  }

  const handleSubmit = async () => {
    const res: any = await API.modifyCourse({
      id: courseInfo.id,
      courseVenueUserId: value
    })
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success('编辑成功')
      handleClose(true)
    } else {
      Message.error(message)
    }
  }

  return (
    <Modal
      title={'编辑'}
      open={visible}
      width={520}
      onOk={() => handleSubmit()}
      onCancel={() => handleClose(false)}
      wrapClassName="class-detail-modal"
    >
      <Spin spinning={loading}>
        <div className='class-info-wrapper'>
          <div className='top-card'>
            <div className='class-title-box'>
              <img src={fileResourcePath(classIcon)} alt='图标' />
              <span title={detailData.courseName || ''}>{detailData.courseName || ''}</span>
              <div className='difficulty-box'>
                <span>难度</span>
                <Rate disabled key={detailData.courseDifficulty} defaultValue={detailData.courseDifficulty || 0} />
              </div>
            </div>
            <div className='info-list'>
              <div className='info-item'>
                <span>课程教室</span>
                <span title={detailData.classroomName || ''}>{detailData.classroomName || ''}</span>
              </div>
              <div className='info-item'>
                <span>可预约数</span>
                <span>{detailData.courseCanReserve || '0'}</span>
              </div>
            </div>
          </div>
          <div className='other-info-item'>
            <span>课程教练</span>
            <Select
              value={value}
              style={{ width: '200px' }}
              onChange={setValue}
              placeholder="请选择教练"
              options={coachList}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default Page
