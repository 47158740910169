import React, { useState, useEffect } from 'react'
import { Modal, App, Form, InputNumber, DatePicker, Radio, Input } from 'antd'
import { cardTypeList, customerSexList } from '../../../../../constants'
import { API } from '../../../../../api'
import type { RangePickerProps } from 'antd/es/date-picker';
import './index.scss'
import dayjs from 'dayjs'

interface IProps {
  info: any;
  visible: boolean;
  handleClose: Function;
}

const Page: React.FC<IProps> = ({ info, visible, handleClose }) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()
  const [selectCardType, setSelectCardType] = useState<string>('1')

  const formItemLayoutNormal = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  useEffect(() => {
    if (visible) {
      setSelectCardType('1')
      form && form.resetFields()
      if (info.id) {
        form.setFieldsValue({
          customerName: info.customerName,
          customerPhone: info.customerPhone,
          customerSex: info.customerSex + '',
          customerBirthday: dayjs(info.customerBirthday),
        })
      } else {
        form.setFieldsValue({
          cardType: '1'
        })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const handleSubmit = async () => {
    try {
      const values: any = await form.validateFields();
      let params: any = {
        ...values,
        customerBirthday: values.customerBirthday ? values.customerBirthday.format('YYYY-MM-DD') : undefined,
      }
      if (info.id) {
        params.id = info.id
      } else {
        params.expireDate = values.expireDate.format('YYYY-MM-DD')
      }
      addMember(params)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }

  const addMember = async (params: any) => {
    let apiName = info.id ? 'updateCustomer' : 'createCustomer'
    const res: any = await API[apiName](params)
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success(info.id ? '编辑成功' : '新增成功')
      handleClose(true)
    } else {
      Message.error(message)
    }
  } 

  const handleCardTypeChange = (e: any) => {
    let val = e.target.value
    setSelectCardType(val)
    if (val === '1') {
      form.setFieldsValue({
        courseNum: undefined,
      })
    }
  }

  const disabledBirDate: RangePickerProps['disabledDate'] = (current) => {
    // 不能选择当天以及之后的日期
    const today = dayjs().startOf('day');
    const currentDate = dayjs(current).startOf('day');
    return currentDate.isAfter(today) || currentDate.isSame(today);
  };

  const disabledExpireDate: RangePickerProps['disabledDate'] = (current) => {
    // 不能选择当天之前的日期
    const today = dayjs().startOf('day');
    const currentDate = dayjs(current).startOf('day');
    return currentDate.isBefore(today);
  };

  const validatorCheckName = async (rule: any, value: any, callback: any) => {
    if (value) {
      const res: any = await API.checkCustomerName({
        customerName: value,
        id: info.id || undefined,
      })
      if (!res) return false
      const { code, data, message } = res
      if (code === '000000') {
        if (!data) {
          return Promise.resolve()
        } else {
          return Promise.reject('会员名称重复')
        }
      } else {
        return Promise.reject(message)
      }
    } else {
      return Promise.reject('请输入会员名称')
    }
  }

  const validatorCheckPhone = async (rule: any, value: any, callback: any) => {
    if (value) {
      let reg: any = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (!reg.test(value)) {
        return Promise.reject('请输入正确的会员号码')
      } else {
        const res: any = await API.checkCustomerPhone({
          id: info.id || undefined,
          customerPhone: value
        })
        if (!res) return false
        const { code, data, message } = res
        if (code === '000000') {
          if (!data) {
            return Promise.resolve()
          } else {
            return Promise.reject('会员号码重复')
          }
        } else {
          return Promise.reject(message)
        }
      }
    } else {
      return Promise.reject('请输入会员号码')
    }
  }

  return (
    <Modal
      title={info.id ? '编辑会员' : '新增会员'}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => handleClose(false)}
      width={650}
      wrapClassName="add-member-modal"
    >
      <Form form={form}>
        <div className='form-bg'>
          <Form.Item
            {...formItemLayoutNormal}
            name="customerName"
            label="会员名称"
            required
            rules={[{ validator: validatorCheckName }]}
            validateTrigger="onBlur"
          >
            <Input placeholder="请输入会员名称" />
          </Form.Item>
          <Form.Item
            {...formItemLayoutNormal}
            name="customerPhone"
            label="会员号码"
            required
            rules={[{ validator: validatorCheckPhone }]}
            validateTrigger="onBlur"
          >
            <Input placeholder="请输入会员号码" />
          </Form.Item>
          <Form.Item
            className='without-margin-bottom'
            {...formItemLayoutNormal}
            name="customerSex"
            label="会员性别"
            rules={[{ required: false, message: '请选择会员性别' }]}
          >
            <Radio.Group options={customerSexList} />
          </Form.Item>
          <Form.Item
            className='without-margin-bottom'
            {...formItemLayoutNormal}
            name="customerBirthday"
            label="会员生日"
            rules={[{ required: false, message: '请选择会员生日' }]}
          >
            <DatePicker disabledDate={disabledBirDate} style={{ width: '100%' }} placeholder="请选择会员生日" />
          </Form.Item>
        </div>
        {!info.id && <div className='form-bg'>
          <Form.Item
            {...formItemLayoutNormal}
            name="cardType"
            label="会员卡类型"
            rules={[{ required: true, message: '请选择会员卡类型' }]}
          >
            <Radio.Group onChange={handleCardTypeChange} options={[cardTypeList[0], cardTypeList[1]]} />
          </Form.Item>
          {selectCardType === '1' ? <Form.Item
            {...formItemLayoutNormal}
            name="courseNum"
            label="课程次数"
            rules={[
              { required: true, message: '请输入课程次数' },
            ]}
          >
            <InputNumber precision={0} style={{ width: '100%' }} min={1} placeholder="请输入课程次数" />
          </Form.Item> : <div className='ant-form-item'></div>}
          <Form.Item
            {...formItemLayoutNormal}
            name="expireDate"
            label="截止日期"
            className='without-margin-bottom'
            rules={[{ required: true, message: '请选择截止日期' }]}
          >
            <DatePicker disabledDate={disabledExpireDate} style={{ width: '100%' }} placeholder="请选择截止日期" />
          </Form.Item>
        </div>}
      </Form>
    </Modal>
  )
}

export default Page
