// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useRef } from 'react'
import { App, Modal } from 'antd'
import { API } from '../../../../api'
import '../index.scss'
import { fileResourcePath } from '../../../../utils/index'

const Page: React.FC<any> = ({visible, viewVideoObj, viewChild}) => {
  const { message: Message } = App.useApp()

  useEffect(() => {
    if (visible) {
      incrShowTimes()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const incrShowTimes = async () => {
    const res: any = await API.incrShowTimes({
      id: viewVideoObj.id
    })
    if (!res) return false
  }

  const handleOk = () => {
    viewChild(true)
  }

  const handleCancel = () => {
    viewChild(true)
  }
  
  return (
    <Modal 
      title="查看视频" 
      width={500} 
      open={visible}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-account-modal"
    >
      <video controls style={{width: '100%', maxWidth: '500px'}}>
        <source src={fileResourcePath(viewVideoObj.viPath)} />
      </video>
    </Modal>
  )
}

export default Page
