import React, { useState, useEffect } from 'react'
import { Table, DatePicker, Select, Input, App, Button } from 'antd'
import { cardTypeMap, cardTypeList, operateTypeMap } from '../../../constants'
import { API } from '../../../api'
import { formatDate, fileResourcePath } from '../../../utils'
import { RedoOutlined } from '@ant-design/icons'
import './index.scss'

const dayjs = require('dayjs')
const { RangePicker } = DatePicker

interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC = () => {
  const { message: Message } = App.useApp()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [searchParams, setSearchParams] = useState<any>({
    createDate: [dayjs().startOf('month'), dayjs()],
    cardType: undefined,
    customerPhone: undefined,
    weixinName: undefined,
  });
  const [dataSource, setDataSource] = useState<any>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, record: any, index: number) => {
        return index + 1
      },
      width: 80,
      fixed: true
    },
    {
      title: '会员号码',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
      width: 150,
      ellipsis: true
    },
    {
      title: '微信昵称',
      dataIndex: 'weixinName',
      key: 'weixinName',
      width: 150,
      ellipsis: true
    },
    {
      title: '微信头像',
      dataIndex: 'weixinUrl',
      key: 'weixinUrl',
      width: 150,
      render: (text: any) => (<div className='yg-weixin-url-col'>
        <img src={fileResourcePath(text)} alt='头像' />
      </div>),
    },
    {
      title: '操作类型',
      dataIndex: 'operateType',
      key: 'operateType',
      width: 130,
      render: (text: any) => operateTypeMap[text] || ''
    },
    {
      title: '会员卡号',
      dataIndex: 'cardNo',
      key: 'cardNo',
      width: 150,
      ellipsis: true
    },
    {
      title: '会员卡类型',
      dataIndex: 'cardType',
      key: 'cardType',
      width: 130,
      render: (text: any) => cardTypeMap[text] || ''
    },
    {
      title: '次数',
      dataIndex: 'courseNum',
      key: 'courseNum',
      width: 150,
      render: (text: any, record: any) => <div>
        {record.numOperateType + '' === '1' && <span style={{ color: '#40C3A8' }}>+{text || '--'}</span>}
        {record.numOperateType + '' === '2' && <span style={{ color: '#FF5B68' }}>-{text || '--'}</span>}
      </div>
    },
    {
      title: '开始日期',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 150,
      render: (text: any) => formatDate(text) || ''
    },
    {
      title: '截止日期',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 150,
      render: (text: any) => formatDate(text) || ''
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 180,
      ellipsis: true
    },
    {
      title: '创建人',
      dataIndex: 'createUserName',
      key: 'createUserName',
      width: 150,
      ellipsis: true
    },
  ]

  const scrollX: number = columns.reduce((pre: number, curr: any) => {
    return pre + (curr.width || 100)
  }, 0)

  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageInfo])

  const getList = async () => {
    let params: any = {
      ...searchParams,
      page: pageInfo.current,
      size: pageInfo.pageSize,
      startDate: searchParams.createDate ? searchParams.createDate[0].format('YYYY-MM-DD')  : undefined,
      endDate: searchParams.createDate ? searchParams.createDate[1].format('YYYY-MM-DD') : undefined,
    }
    delete params.createDate
    setTableLoading(true)
    const res: any = await API.cardOrderList(params)
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handleFilterChange = (key: string, value: any) => {
    setSearchParams({
      ...searchParams,
      [key]: value
    })
  }

  const handleSearch = () => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  return (
    <div className='order-list-page yg-page'>
      <div className='yg-page-header'><span>订单列表</span></div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>创建时间</span>
          <RangePicker
            value={searchParams.createDate}
            style={{ width: 220 }}
            onChange={(date) => handleFilterChange('createDate', date)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>会员类型</span>
          <Select
            allowClear
            placeholder="请选择会员类型"
            value={searchParams.cardType}
            style={{ width: 150 }}
            onChange={(value) => handleFilterChange('cardType', value)}
            options={cardTypeList}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>会员号码</span>
          <Input
            allowClear
            placeholder="请输入会员号码"
            value={searchParams.customerPhone}
            style={{ width: 150 }}
            onChange={(e) => handleFilterChange('customerPhone', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>微信昵称</span>
          <Input
            allowClear
            placeholder="请输入微信昵称"
            value={searchParams.weixinName}
            style={{ width: 150 }}
            onChange={(e) => handleFilterChange('weixinName', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <Button className='yg-ghost-btn' onClick={handleSearch}>查询</Button>
        </div>
        <Button className='yg-refresh-btn' onClick={handleSearch}>
          <RedoOutlined />
        </Button>
      </div>
      <div className='yg-page-container'>
        <div className='yg-page-main'>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={tableLoading}
            scroll={{ x: scrollX }}
            pagination={{
              ...pageInfo,
              total,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共 ${total} 条`,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Page
