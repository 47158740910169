import React, { useState, useEffect } from 'react'
import * as echarts from 'echarts/core'
import { LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent
} from 'echarts/components'
import { LabelLayout, UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import lodash from 'lodash'
import './index.scss'

const dayjs = require('dayjs')

interface IProps {
  data: any;
}

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);

const Page: React.FC<IProps> = ({ data }) => {
  const [chartEle, setChartEle] = useState<any>(null)
  const [options, setOptions] = useState<any>({
    color: ['#9E896A'],
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      left: '3%',
      top: '28%',
      right: '3%',
      bottom: '2%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: '#C0C3CC',
        },
        // onZero: false,
        symbol: ['none', 'arrow'],
        symbolSize: [5, 8],
        symbolOffset: [4],
      },
      axisTick: {
        length: 1,
        // alignWithLabel: true,
        lineStyle: {
          type: 'dotted',
          color: '#C0C3CC',
          width: 2,
          cap: 'round'
        }
      },
      data: []
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
      axisLine: {
        lineStyle: {
          color: '#C0C3CC',
        }
      },
      splitLine: {
        show: false
      }
    },
    series: [
      {
        data: [],
        type: 'line',
        smooth: true,
        lineStyle: {
          color: '#9E896A',
        },
        showSymbol: false,
        emphasis: {
          disabled: true,
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#FFECBD' // 0% 处的颜色
            }, {
                offset: 1, color: 'rgba(255,255,255,0)' // 100% 处的颜色
            }],
            global: false
          }
        }
      }
    ]
  })

  useEffect(() => {
    initCharts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    updateOptions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const initCharts = () => {
    let newChart = echarts.init(document.getElementById('line_chart'))
    newChart.setOption(options);
    window.addEventListener('resize', function () {
      newChart.resize();
    });
    setChartEle(newChart)
  }

  const updateOptions = () => {
    if (!chartEle) {
      return false
    }
    let newOptions = lodash.cloneDeep(options)
    let yData: any[] = []
    let xData: any[] = []
    data.forEach((ele: any) => {
      yData.push(ele.reserveNum || 0)
      xData.push(ele.reserveDate ? dayjs(ele.reserveDate, 'YYYY-MM-DD HH:mm:ss').format('MM-DD') : '')
    })
    newOptions.xAxis.data = xData
    newOptions.series[0].data = yData
    chartEle.setOption(newOptions)
    setOptions(newOptions)
  }

  return (
    <div id='line_chart' className="line-chart-box"></div>
  )
}

export default Page
