import React, { useEffect } from 'react'
import { Modal, App, Form, InputNumber, Input, Radio } from 'antd'
import { API } from '../../../../../api'
import './index.scss'

const { TextArea } = Input

const operateTypeList = [
  { label: '增加', value: '1' },
  { label: '扣减', value: '2' },
]

interface IProps {
  info: any;
  visible: boolean;
  handleClose: Function;
}

const Page: React.FC<IProps> = ({ info, visible, handleClose }) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()

  const formItemLayoutOneLine = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  }

  useEffect(() => {
    if (visible) {
      init()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const init = () => {
    form.setFieldsValue({
      operateType: '1',
    })
  }

  const handleSubmit = async () => {
    try {
      const values: any = await form.validateFields();
      let params: any = {
        customerId: info.id,
        ...values,
      }
      modifyIntegral(params)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }

  const modifyIntegral = async (params: any) => {
    const res: any = await API.modifyIntegral(params)
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success('调整成功')
      handleClose(true)
    } else {
      Message.error(message)
    }
  }

  return (
    <Modal
      title="积分调整"
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => handleClose(false)}
      width={640}
      wrapClassName="judge-modal"
    >
      <div className='yg-user-info-card'>
        <div className='card-title'>
          <span>您正在操作的会员：</span>
          <span>【{info.weixinName}】</span>
        </div>
        <div className='info-list'>
          <div className='info-item'>
            <span>当前积分</span>
            <span>{info.customerIntegral}</span>
          </div>
          <div className='info-item'>
            <span>会员号码</span>
            <span>{info.customerPhone}</span>
          </div>
          <div className='info-item'>
            <span>会员卡号</span>
            <span>{info.cardNo}</span>
          </div>
        </div>
      </div>
      <Form form={form}>
        <Form.Item
          {...formItemLayoutOneLine}
          name="operateType"
          label="操作类型"
          rules={[{ required: true, message: '请选择操作类型' }]}
        >
          <Radio.Group options={operateTypeList} />
        </Form.Item>
        <Form.Item
          {...formItemLayoutOneLine}
          dependencies={['operateType']}
          name="integralNum"
          label="积分"
          rules={[
            { required: true, message: '请输入积分数' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue('operateType') === '2' && value > info.customerIntegral) {
                  return Promise.reject(new Error('扣减数量不可超过当前积分数'));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber precision={0} style={{ width: 120 }} min={1} placeholder="请输入积分数" />
        </Form.Item>
        <Form.Item 
          {...formItemLayoutOneLine}
          label="备注" 
          name="remark"
          rules={[{ required: false, message: '请输入备注' }]}
        >
          <TextArea rows={6} placeholder='请输入备注' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
