import React, { useState, useEffect } from 'react'
import { Modal, App, Spin, Rate } from 'antd'
import { API } from '../../api'
import { fileResourcePath } from '../../utils/index'
import classIcon from '../../assets/images/class_icon.png'
import dayjs from 'dayjs'
import './index.scss'

const repeatTypeMap: any =  {
  '2': '按周',
  '3': '按月',
}

interface IProps {
  visible: boolean;
  courseInfo: any;
  handleCancel: Function;
}

const Page: React.FC<IProps> = ({ visible, courseInfo, handleCancel }) => {
  const { message: Message } = App.useApp()
  const [loading, setLoading] = useState<boolean>(false)
  const [detailData, setDetailData] = useState<any>({})

  useEffect(() => {
    if (visible) {
      getData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const getData = async () => {
    let params: any = {
      id: courseInfo.id,
    }
    setLoading(true)
    const res: any = await API.courseDetail(params)
    setLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDetailData(data || {})
    } else {
      Message.error(message)
    }
  }

  return (
    <Modal
      title={courseInfo.courseName || ''}
      open={visible}
      onCancel={() => handleCancel()}
      width={520}
      footer={null}
      wrapClassName="class-detail-modal"
    >
      <Spin spinning={loading}>
        <div className='class-info-wrapper'>
          <div className='top-card'>
            <div className='class-title-box'>
              <img src={fileResourcePath(classIcon)} alt='图标' />
              <span title={detailData.courseName || ''}>{detailData.courseName || ''}</span>
              <div className='difficulty-box'>
                <span>难度</span>
                <Rate disabled key={detailData.courseDifficulty} defaultValue={detailData.courseDifficulty || 0} />
              </div>
            </div>
            <div className='info-list'>
              <div className='info-item'>
                <span>课程教室</span>
                <span title={detailData.classroomName || ''}>{detailData.classroomName || ''}</span>
              </div>
              <div className='info-item'>
                <span>课程教练</span>
                <span title={detailData.userName || ''}>{detailData.userName || ''}</span>
              </div>
              <div className='info-item'>
                <span>可预约数</span>
                <span>{detailData.courseCanReserve || '0'}</span>
              </div>
            </div>
          </div>
          <div className='other-info-item'>
            <span>课程日期</span>
            <span>{detailData.courseDate ? dayjs(detailData.courseDate).format('YYYY-MM-DD') : ''}</span>
          </div>
          <div className='other-info-item'>
            <span>时间段</span>
            <span>{detailData.courseSlot}</span>
          </div>
          <div className='other-info-item'>
            <span>课程周期</span>
            <span>{detailData.courseRepetNum || '0'}</span>
          </div>
          <div className='other-info-item'>
            <span>周期类型</span>
            <span>{repeatTypeMap[detailData.courseRepetType] || '--'}</span>
          </div>
          <div className='line'></div>
          <div className='other-info-item'>
            <span>课程描述</span>
            <span>{detailData.courseDesc || '--'}</span>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default Page
