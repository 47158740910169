// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Modal, Form, Input } from 'antd'
import { API } from '../../../../api'
import '../index.scss'

const Page: React.FC<any> = ({visible, modalChild}) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  useEffect(() => {
    if (visible) {
      // getList()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const submitForm = async (values: object) => {
    setConfirmLoading(true)
    const res: any = await API.createSysRole({
      ...values
    })
    setConfirmLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      Message.success('操作成功')
      modalChild(true)
      handleCancel()
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      submitForm(values)
    } catch (errorInfo) {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmLoading(false)
    modalChild(false)
  }

  return (
    <Modal 
      title="新增角色" 
      width={500} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-role-modal"
    >
      <Form preserve={false} form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
        <Form.Item 
          label="角色名称" 
          name="roleName"
          rules={[{ required: true, message: '请输入角色名称' }]}
        >
          <Input placeholder="请输入角色名称" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
