// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { Calendar } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import ClassCalendar from '../../../../../components/class_calendar'
import ClassDetailModal from '../../../../../components/class_detail_modal'
import dayjs from 'dayjs'
import './index.scss'

interface IProps {
  refreshKey: string;
}

const Page: React.FC<IProps> = ({ refreshKey }) => {
  const [selectDate, setSelectDate] = useState<any>(dayjs())
  const [visible, setVisible] = useState<boolean>(false)
  const [selectClass, setSelectClass] = useState<any>({})

  const handView = (record: any) => {
    setSelectClass(record)
    setVisible(true)
  }

  const disabledDate = (curr: any) => {
    return curr.isBefore(dayjs().subtract(1, 'day'))
  }

  return (
    <div className='class-calendar-tab'>
      <div className='left-calendar-wrapper'>
        <ClassCalendar onSelect={handView} key={refreshKey} initDate={selectDate}/>
      </div>
      <div className='right-calendar-wrapper'>
        <Calendar
          disabledDate={disabledDate}
          onChange={(val) => setSelectDate(val)}
          headerRender={({ value, type, onChange }) => {
            return <div className='right-calendar-header'>
              <LeftOutlined onClick={() => onChange(value.subtract(1, 'month'))} />
              <span>{value.format('YYYY年 M月')}</span>
              <RightOutlined onClick={() => onChange(value.add(1, 'month'))} />
            </div>
          }}
          fullscreen={false}
        />
      </div>
      <ClassDetailModal courseInfo={selectClass} visible={visible} handleCancel={() => setVisible(false)} />
    </div>
  )
}

export default Page
