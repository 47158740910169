import React from 'react';
import { Outlet } from 'react-router-dom';
import './index.scss';


const Page: React.FC = () => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default Page
