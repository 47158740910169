// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Table, Input, Button, Modal } from 'antd'
import { coachSexMap } from '../../../constants'
import { birthdayToAge, fileResourcePath } from '../../../utils/index'
import { API } from '../../../api'
import { RedoOutlined } from '@ant-design/icons'
import './index.scss'

import AddCoach from './components/add_coach';
interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC = () => {
  const { message: Message } = App.useApp()
  const [modal, contextHolder] = Modal.useModal();
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [searchParams, setSearchParams] = useState<any>({
    venueUserName: undefined
  });
  const [dataSource, setDataSource] = useState<any>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, record: any, index: number) => {
        return index + 1
      },
      width: 80,
      fixed: 'left'
    },
    {
      title: '场馆名称',
      dataIndex: 'venueName',
      key: 'venueName',
      width: 150,
      ellipsis: true
    },
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
      ellipsis: true
    },
    {
      title: '性别',
      dataIndex: 'userSex',
      key: 'userSex',
      render: (text: any) => coachSexMap[text]?.text || '',
      width: 120,
    },
    {
      title: '年龄',
      dataIndex: 'userBirthday',
      key: 'userBirthday',
      render: (text: any) => text ? birthdayToAge(text) : undefined,
      width: 150,
      ellipsis: true
    },
    {
      title: '电话',
      dataIndex: 'userPhone',
      key: 'userPhone',
      width: 150,
      ellipsis: true
    },
    {
      title: '头像',
      dataIndex: 'userHeadPic',
      key: 'userHeadPic',
      width: 150,
      render: (text: any) => (<div className='yg-weixin-url-col'>
        <img src={fileResourcePath(text)} alt='头像' />
      </div>),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 180,
      ellipsis: true
    },
    {
      title: '修改时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 180,
      ellipsis: true
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      width: 160,
      ellipsis: true,
      fixed: 'right',
      render: (text: any, record: object) => (
        <div className='yg-operate-col'>
          <span className="yg-operate-btn" onClick={() => handleOperate('edit', { ...record })}>
            编辑
          </span>
          <span className="yg-operate-btn" onClick={() => handleOperate('delete', { ...record })}>
            删除
          </span>
        </div>
      )
    },
  ]

  const scrollX: number = columns.reduce((pre: number, curr: any) => {
    return pre + (curr.width || 100)
  }, 0)

  const [modalVisible, setModalVisible] = useState<Boolean>(false)
  const [editObj, setEditObj] = useState<object>({})

  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo])

  const getList = async () => {
    setTableLoading(true)
    const res: any = await API.coachList({
      ...searchParams,
      page: pageInfo.current,
      size: pageInfo.pageSize,
    })
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handleFilterChange = (key: string, value: any) => {
    setSearchParams({
      ...searchParams,
      [key]: value
    })
  }

  const handleSearch = () => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    })
  }
  
  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const handleOperate = (type: string, record: object) => {
    switch (type) {
      case 'add': {
        setModalVisible(true)
        setEditObj({
          ...record,
          operateType: 'add'
        })
        break;
      }
      case 'edit': {
        setModalVisible(true)
        setEditObj({
          ...record,
          operateType: 'edit'
        })
        break;
      }
      case 'delete': {
        handleDelete(record)
        break;
      }
      default:
        break;
    }
  }

  const handleDelete = async (record: any) => {
    const confirmed = await modal.confirm({
      title: '提示',
      content: (
        <>确认删除教练【{record.userName}】吗？</>
      ),
    });
    if (confirmed) {
      deleteReq(record)
    }
  }

  const deleteReq = async (record: any) => {
    const res: any = await API.deleteCoach({ id: record.id })
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success('删除成功')
      handleSearch()
    } else {
      Message.error(message)
    }
  }

  const modalOperate = (isOk: Boolean) => {
    setModalVisible(false)
    if (isOk) {
      handleSearch()
    }
  }

  return (
    <div className='coach-list-page yg-page'>
      <div className='yg-page-header'><span>教练列表</span></div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>教练姓名</span>
          <Input
            allowClear
            placeholder="请输入教练姓名"
            value={searchParams.venueUserName}
            style={{ width: 180 }}
            onChange={(e) => handleFilterChange('venueUserName', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <Button type="primary" className='yg-ghost-btn' onClick={handleSearch}>查询</Button>
        </div>
        <Button className='yg-refresh-btn' onClick={handleSearch}>
          <RedoOutlined />
        </Button>
        <div className='yg-page-filter-item-right'>
          <Button type="primary" onClick={() => handleOperate('add', {})}>新增教练</Button>
        </div>
      </div>
      <div className='yg-page-container' style={{ height: 'calc(100% - 54px - 48px)' }}>
        <div className='yg-page-main'>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={tableLoading}
            scroll={{ x: scrollX }}
            pagination={{
              ...pageInfo,
              total,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共 ${total} 条`,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
      {contextHolder}
      <AddCoach visible={modalVisible} modalChild={modalOperate} editObj={editObj} />
    </div>
  )
}

export default Page
