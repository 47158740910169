// antd 覆盖主题变量
const themeToken: any = {
  token: {
    colorPrimary: '#9E896A',
    textFontSize: '14',
    borderRadius: '2px',
    defaultBorderColor: '#DFE1E5',
    colorText: '#252A3D',
    colorBorder: '#DFE1E5',
  },
  components: {
    Button: {
      contentFontSize: 12,
    },
    Table: {
      borderColor: '#DFE8FF',
      headerBg: '#F8F7F5',
      headerBorderRadius: 0,
      headerColor: '#666A77',
      headerSplitColor: '#F8F7F5',
    },
    Form: {
      labelFontSize: 12,
    }
  }
}

export default themeToken