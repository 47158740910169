import React, { useState, useEffect } from 'react'
import { Modal, App } from 'antd'
import { API } from '../../../../../api'
import ClassCalendar from '../../../../../components/class_calendar'
import dayjs from 'dayjs'
import lodash from 'lodash'
import './index.scss'

interface IProps {
  info: any;
  visible: boolean;
  handleClose: Function;
}

const Page: React.FC<IProps> = ({ info, visible, handleClose }) => {
  const { message: Message } = App.useApp()
  const [selectClass, setSelectClass] = useState<any>({})
  const [refreshKey, setRefreshKey] = useState<string>('')

  useEffect(() => {
    if (visible) {
      init()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const init = () => {
    setSelectClass({})
    setRefreshKey(+new Date() + '')
  }

  const handleSubmit = async () => {
    if (lodash.isEmpty(selectClass)) {
      Message.warning('请先选择课程')
      return false
    }
    const res: any = await API.reserveCourse({
      customerId: info.id,
      courseId: selectClass.id,
      reserveDate: dayjs(selectClass.courseDate).format('YYYY-MM-DD'),
      courseWeekDay: selectClass.courseWeekDay,
      reserveTime: selectClass.courseSlot,
    })
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success('预约成功')
      handleClose(true)
    } else {
      Message.error(message)
    }
  }

  return (
    <Modal
      title="预约课程"
      centered
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => handleClose(false)}
      width={800}
      wrapClassName="reserve-class-modal"
    >
      <div className='yg-user-info-card'>
        <div className='card-title'>
          <span>您正在操作的会员：</span>
          <span>【{info.weixinName}】</span>
        </div>
        <div className='info-list'>
          <div className='info-item'>
            <span>会员号码</span>
            <span>{info.customerPhone || ''}</span>
          </div>
          {info.cardNo && <div className='info-item'>
            <span>会员卡号</span>
            <span>{info.cardNo}</span>
          </div>}
          {info.courseNum > 0 && <div className='info-item'>
            <span>当前剩余课程次数</span>
            <span>{info.courseNum || 0}</span>
          </div>}
        </div>
      </div>
      <div className='calendar-box-in-modal'>
          <ClassCalendar key={refreshKey} onSelect={setSelectClass} value={selectClass} mini={true} />
        </div>
    </Modal>
  )
}

export default Page
