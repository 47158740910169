// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Button, Input, Modal, Select, Spin, Empty, Pagination, Tooltip } from 'antd'
import { videoTypeList } from '../../../constants'
import { API } from '../../../api'
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { fileResourcePath } from '../../../utils/index'
import { RedoOutlined } from '@ant-design/icons'
import './index.scss'

import AddVideo from './components/add_video'
import ViewVideo from './components/view_video'
interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC = () => {
  const { message: Message } = App.useApp()
  const [modal, contextHolder] = Modal.useModal();
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [searchParams, setSearchParams] = useState<any>({
    viName: undefined,
    viType: undefined,
  });
  const [dataSource, setDataSource] = useState<any>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const [modalVisible, setModalVisible] = useState<Boolean>(false)
  const [viewVisible, setViewVisible] = useState<Boolean>(false)
  const [viewVideoObj, setViewVideoObj] = useState<object>({})

  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo])

  const getList = async () => {
    let params: any = {
      ...searchParams,
      page: pageInfo.current,
      size: pageInfo.pageSize
    }
    setTableLoading(true)
    const res: any = await API.videoList(params)
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handleFilterChange = (key: string, value: any) => {
    setSearchParams({
      ...searchParams,
      [key]: value
    })
  }

  const handleOperate = (type: string, record?: any) => {
    switch (type) {
      case 'add': {
        setModalVisible(true)
        break
      }
      case 'delete': {
        handleDelete(record)
        break
      }
      case 'view': {
        setViewVisible(true)
        setViewVideoObj(record)
        break
      }
      default:
        break
    }
  } 
  const handleDelete = async (record: any) => {
    const confirmed = await modal.confirm({
      title: '提示',
      content: (
        <>确认删除视频【{record.viName}】吗？</>
      ),
    });
    if (confirmed) {
      deleteReq(record)
    }
  }

  const deleteReq = async (record: any) => {
    const res: any = await API.deleteVideo({ id: record.id })
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success('删除成功')
      handleSearch()
    } else {
      Message.error(message)
    }
  }

  const modalOperate = (isOk: Boolean) => {
    setModalVisible(false)
    if (isOk) {
      handleSearch()
    }
  }

  const viewChild = (isOk: Boolean) => {
    setViewVisible(false)
    if (isOk) {
      handleSearch()
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const handleSearch = () => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    })
  }

  return (
    <div className='video-list-page yg-page'>
      <div className='yg-page-header'><span>视频课程管理</span></div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>视频名称</span>
          <Input
            allowClear
            placeholder="请输入视频名称"
            value={searchParams.viName}
            style={{ width: 180 }}
            onChange={(e) => handleFilterChange('viName', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>视频类型</span>
          <Select
            allowClear
            placeholder="请选择视频类型"
            value={searchParams.viType}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('viType', value)}
            options={videoTypeList}
          />
        </div>
        <div className='yg-page-filter-item'>
          <Button type="primary" className='yg-ghost-btn' onClick={handleSearch}>查询</Button>
        </div>
        <Button className='yg-refresh-btn' onClick={handleSearch}>
          <RedoOutlined />
        </Button>
        <div className='yg-page-filter-item-right'>
          <Button type="primary" onClick={() => handleOperate('add', {})}>上传视频</Button>
        </div>
      </div>

      <div className='yg-page-container' style={{ height: 'calc(100% - 54px - 48px)' }}>
        <div className='yg-page-main video-section'>
          {
            tableLoading ?
            <div className='empty-wrap'><Spin /></div> :
            dataSource.length === 0 ?
            <div className='empty-wrap'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div> :
            <div className='yg-page-section video-section'>
              {dataSource.map((ele: any, index: number) => (
                <div className='video-item'>
                  <div className='video-wrap' onClick={() => handleOperate('view', ele)}>
                    <img src={fileResourcePath(ele.viCoverPath)} alt="封面"/>
                  </div>
                  <div className='name-wrap'>
                    <p className='name'>{ele.viName}</p>
                    <Tooltip title={ele.viDesc}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className='info-wrap'>
                    <p className='times'>{ele.viShowTimes} 次播放</p>
                    <DeleteOutlined onClick={() => handleOperate('delete', ele)} />
                  </div>
                </div>
              ))}
              {
                total  + '' !== '0' &&
                <Pagination
                  current={pageInfo.current}
                  pageSize={pageInfo.pageSize}
                  showSizeChanger
                  showQuickJumper
                  showTotal={(total) => `共 ${total} 条`}
                  total={total}
                  onChange={handlePageChange}
                />
              }
            </div>
          }
        </div>
      </div>
      {contextHolder}
      <AddVideo visible={modalVisible} modalChild={modalOperate} />
      <ViewVideo visible={viewVisible} viewVideoObj={viewVideoObj} viewChild={viewChild} />
    </div>
  )
}

export default Page
