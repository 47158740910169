// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Modal, Form, Input, Select } from 'antd'
import { API } from '../../../../api'
import '../index.scss'
const Base64 = require("js-base64").Base64

const Page: React.FC<any> = ({visible, modalChild, editObj}) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [roleList, setRoleList] = useState<[]>([])
  const [oldPass, setOldPass] = useState<[]>([])
  
  useEffect(() => {
    if (visible) {
      getRoleList()
      form.setFieldsValue({...editObj})
      editObj.operateType === 'edit' && setOldPass(editObj.userPassword)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, editObj])

  const getRoleList = async () => {
    const res: any = await API.sysRoleList({
      page: 1,
      size: 10000,
    })
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setRoleList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const handleSelectRole = (value: string) => {
  }

  // 校验账号名唯一
  const validatorCheckName = async (rule: any, value: any) => {
    if (value) {
      const res: any = await API.checkUserName({
        id: editObj.id || undefined,
        userName: value
      })
      if (!res) return false
      const { code, data, message } = res
      if (code === '000000') {
        if (data) {
          return Promise.resolve()
        } else {
          return Promise.reject('账号名称重复')
        }
      } else {
        return Promise.reject(message)
      }
    } else {
      return Promise.reject('请输入账号')
    }
  }

  const submitForm = async (values: any) => {
    setConfirmLoading(true)
    const res: any = await API.createSysUser({
      id: editObj.id || undefined,
      userName: values.userName,
      roleId: values.roleId,
      userPassword: oldPass === values.userPassword ? oldPass : Base64.encode(values.userPassword), // 未修改密码时，密码传原来的密文
      userPhone: values.userPhone,
      realName: values.realName
    })
    setConfirmLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      Message.success('操作成功')
      modalChild(true)
      handleCancel()
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      submitForm(values)
    } catch (errorInfo) {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmLoading(false)
    modalChild(false)
  }
  
  return (
    <Modal 
      title={editObj.operateType === 'edit' ? '编辑账号' : '新增账号'} 
      width={500} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-account-modal"
    >
      <Form preserve={false} form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
        <Form.Item 
          label="账号" 
          name="userName"
          required
          rules={[{ validator: validatorCheckName }]}
          validateTrigger="onBlur" // 只在失去焦点时触发校验
        >
          <Input placeholder="请输入账号" autoComplete="new-user" />
        </Form.Item>
        <Form.Item 
          label="密码" 
          name="userPassword"
          required
          rules={[
            {
              validator: (rule, value) => {
                let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
                if (!value) {
                  return Promise.reject("请输入密码")
                } else {
                  if (editObj.operateType === "edit") {
                    if (oldPass !== value) {
                      // 修改密码时，校验密码
                      if (!reg.test(value)) {
                        return Promise.reject("密码需要6位以上，且包含数字和字母")
                      } else {
                        return Promise.resolve()
                      }
                    } else {
                      // 不修改密码，沿用旧密码
                      return Promise.resolve()
                    }
                  } else {
                    // 新增时，校验密码
                    if (!reg.test(value)) {
                      return Promise.reject("密码需要6位以上，且包含数字和字母")
                    } else {
                      return Promise.resolve()
                    }
                  }
                }
              }
            }
          ]}
        >
          <Input.Password placeholder="请输入密码" autoComplete="new-password" />
        </Form.Item>
        {
          editObj.operateType === 'add' &&
          <Form.Item 
            label="确认密码" 
            name="confirmPassword"
            required
            rules={[
              {
                validator: (rule, value) => {
                  if (!value) {
                    return Promise.reject("请确认密码")
                  } else {
                    let reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
                    if (reg.test(value)) {
                      if (value === form.getFieldValue('userPassword')) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject("两次输入密码不一致")
                      }
                    } else {
                      return Promise.reject("密码需要6位以上，且包含数字和字母")
                    }
                  }
                }
              }
            ]}
            validateTrigger="onBlur"
          >
            <Input.Password placeholder="请确认密码" />
          </Form.Item>
        }
        <Form.Item 
          label="手机号码" 
          name="userPhone"
          rules={[
            { required: true, message: '请输入手机号码' },
            { pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入正确的手机号码' },
          ]}
        >
          <Input placeholder="请输入手机号码" />
        </Form.Item>
        <Form.Item 
          label="真实姓名" 
          name="realName"
          rules={[{ required: true, message: '请输入真实姓名' }]}
        >
          <Input placeholder="请输入真实姓名" />
        </Form.Item>
        <Form.Item 
          label="角色" 
          name="roleId"
          rules={[{ required: true, message: '请选择角色' }]}
        >
          <Select
            allowClear
            placeholder="请选择角色"
            onChange={(value) => handleSelectRole(value)}
            options={roleList}
            fieldNames={{
              label: 'roleName',
              value: 'id'
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
