import React, { useState, useEffect } from 'react'
import { Table, Select, Input, App, Button } from 'antd'
import { cardTypeList, cardTypeMap } from '../../../constants'
import { formatDate, fileResourcePath } from '../../../utils'
import IntegralDetailModal from './components/integral_detail_modal'
import NewCardModal from './components/new_card_modal'
import ReserveClassModal from './components/reserve_class_modal'
import JudgeModal from './components/judge_modal'
import AddModal from './components/add_modal'
import { API } from '../../../api'
import { RedoOutlined } from '@ant-design/icons'
import './index.scss'

const dayjs = require('dayjs')

interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC = () => {
  const { message: Message } = App.useApp()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [modalKey, setModalKey] = useState<any>(+new Date() + '')
  const [searchParams, setSearchParams] = useState<any>({
    cardType: undefined,
    customerPhone: undefined,
    customerName: undefined,
    weixinName: undefined,
  });
  const [dataSource, setDataSource] = useState<any>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false) 
  const [judgeVisible, setJudgeVisible] = useState<boolean>(false) 
  const [addModalVisible, setAddModalVisible] = useState<boolean>(false) 
  const [cardModalVisible, setCardModalVisible] = useState<boolean>(false) 
  const [reserveModalVisible, setReserveModalVisible] = useState<boolean>(false) 
  const [currentItem, setCurrentItem] = useState<any>({}) 
  
  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, record: any, index: number) => {
        return index + 1
      },
      width: 80,
      fixed: 'left',
    },
    {
      title: '会员号码',
      dataIndex: 'customerPhone',
      key: 'customerPhone',
      width: 150,
      ellipsis: true
    },
    {
      title: '会员名称',
      dataIndex: 'customerName',
      key: 'customerName',
      width: 150,
      ellipsis: true
    },
    {
      title: '微信昵称',
      dataIndex: 'weixinName',
      key: 'weixinName',
      width: 150,
      ellipsis: true
    },
    {
      title: '微信头像',
      dataIndex: 'weixinUrl',
      key: 'weixinUrl',
      width: 150,
      render: (text: any) => (<div className='yg-weixin-url-col'>
        <img src={fileResourcePath(text)} alt='头像' />
      </div>),
    },
    {
      title: '会员卡号',
      dataIndex: 'cardNo',
      key: 'cardNo',
      width: 150,
      render: (text: any) => text || '--',
      ellipsis: true
    },
    {
      title: '会员卡类型',
      dataIndex: 'cardType',
      key: 'cardType',
      width: 130,
      render: (text: any) => cardTypeMap[text] || '--',
    },
    {
      title: '积分',
      dataIndex: 'customerIntegral',
      key: 'customerIntegral',
      width: 150,
      ellipsis: true
    },
    {
      title: '剩余次数',
      dataIndex: 'courseNum',
      key: 'courseNum',
      width: 150,
      render: (text: any, record: any) => (
        // 期限卡类型 剩余次数为 不限
        // 剩余次数为0时，剩余次数显示为红色
        record.cardType + '' === '2' ?
          <span>不限</span> :
          <span style={ text === 0 ? { color: 'red' } : {}}>{ (text === 0 || text) ? text : '--' }</span>
      )
    },
    {
      title: '截止日期',
      dataIndex: 'expireDate',
      key: 'expireDate',
      width: 150,
      render: (text: any, record: any) => (
        // 截止日期已过，截止日期显示为红色
        <span style={ (text && dayjs(dayjs().format('YYYYMMDD')).isAfter(dayjs(text)) ) ? { color: 'red' } : {}}>{ formatDate(text) || '--' }</span>
      )
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 180,
      ellipsis: true
    },
    {
      title: '修改人',
      dataIndex: 'updateUserName',
      key: 'updateUserName',
      width: 150,
      ellipsis: true
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      width: 300,
      fixed: 'right',
      render: (text: any, record: any) => {
        let isShowAppointment = true
        // 截止日期已过，不显示预约课程；
        if (record.expireDate && dayjs(dayjs().format('YYYYMMDD')).isAfter(dayjs(record.expireDate))) {
          isShowAppointment = false
        } else {
          if (record.cardType + '' !== '2' && !record.courseNum) {
            // 不是期限卡 剩余次数等于0时，不显示预约课程；
            isShowAppointment = false
          }
        }
        return (
        // 会员卡号为空的，只显示开卡操作；
        <div className='yg-operate-col'>
          {
            !record.cardNo ? 
              <span className='yg-operate-btn' onClick={() => handleOperate('new', record)}>开卡</span> :
              <>
                <span className='yg-operate-btn' onClick={() => handleOperate('renew', record)}>续费</span>
                {isShowAppointment && <span className='yg-operate-btn' onClick={() => handleOperate('appointment', record)}>预约课程</span>}
                <span className='yg-operate-btn' onClick={() => handleOperate('edit', record)}>编辑</span>
                <span className='yg-operate-btn' onClick={() => handleOperate('adjustIntegral', record)}>积分调整</span>
                <span className='yg-operate-btn' onClick={() => handleOperate('integralDetail', record)}>积分明细</span>
              </>
          }
        </div>
      )}
    },
  ]

  const scrollX: number = columns.reduce((pre: number, curr: any) => {
    return pre + (curr.width || 100)
  }, 0)

  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo])

  const getList = async () => {
    let params: any = {
      ...searchParams,
      page: pageInfo.current,
      size: pageInfo.pageSize,
    }
    setTableLoading(true)
    const res: any = await API.customerList(params)
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handleFilterChange = (key: string, value: any) => {
    setSearchParams({
      ...searchParams,
      [key]: value
    })
  }

  const handleSearch = () => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const handleCloseCardModal = (isOk: boolean) => {
    if (isOk) {
      handleSearch()
    }
    setCardModalVisible(false)
  }

  const handleCloseReserveModal = (isOk: boolean) => {
    if (isOk) {
      handleSearch()
    }
    setReserveModalVisible(false)
  }

  const handleCloseJudgeModal = (isOk: boolean) => {
    if (isOk) {
      handleSearch()
    }
    setJudgeVisible(false)
  }
  const handleCloseAddModal = (isOk: boolean) => {
    if (isOk) {
      handleSearch()
    }
    setAddModalVisible(false)
  }

  const handleOperate = (type: string, record?: any) => {
    setCurrentItem(record)
    setModalKey(+new Date() + '')
    switch (type) {
      case 'new':
      case 'renew': {
        setCardModalVisible(true)
        break
      }
      case 'edit':
      case 'add': {
        setAddModalVisible(true)
        break
      }
      case 'appointment': {
        setReserveModalVisible(true)
        break
      }
      case 'adjustIntegral': {
        setJudgeVisible(true)
        break
      }
      case 'integralDetail': {
        setVisible(true)
        break
      }
      default:
        break
    }
  }

  return (
    <div className='member-list-page yg-page'>
      <div className='yg-page-header'><span>会员列表</span></div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>会员卡类型</span>
          <Select
            allowClear
            placeholder="请选择会员卡类型"
            value={searchParams.cardType}
            style={{ width: 160 }}
            onChange={(value) => handleFilterChange('cardType', value)}
            options={cardTypeList}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>会员号码</span>
          <Input
            allowClear
            placeholder="请输入会员号码"
            value={searchParams.customerPhone}
            style={{ width: 150 }}
            onChange={(e) => handleFilterChange('customerPhone', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>会员名称</span>
          <Input
            allowClear
            placeholder="请输入会员名称"
            value={searchParams.customerName}
            style={{ width: 150 }}
            onChange={(e) => handleFilterChange('customerName', e.target.value)}
          />
        </div>
      </div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>微信昵称</span>
          <Input
            allowClear
            placeholder="请输入微信昵称"
            value={searchParams.weixinName}
            style={{ width: 150 }}
            onChange={(e) => handleFilterChange('weixinName', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <Button className='yg-ghost-btn' onClick={handleSearch}>查询</Button>
        </div>
        <Button className='yg-refresh-btn' onClick={handleSearch}>
          <RedoOutlined />
        </Button>
        <div className='yg-page-filter-item-right'>
          <Button type="primary" onClick={() => handleOperate('add', {})}>新增会员</Button>
        </div>
      </div>
      <div className='yg-page-container'>
        <div className='yg-page-main'>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={tableLoading}
            scroll={{ x: scrollX }}
            pagination={{
              ...pageInfo,
              total,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共 ${total} 条`,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
      <JudgeModal key={'judge_' + modalKey} info={currentItem} visible={judgeVisible} handleClose={handleCloseJudgeModal} /> 
      <ReserveClassModal key={'reserve_' + modalKey} info={currentItem} visible={reserveModalVisible} handleClose={handleCloseReserveModal} /> 
      <NewCardModal key={'card_' + modalKey} info={currentItem} visible={cardModalVisible} handleClose={handleCloseCardModal} /> 
      <AddModal key={'add_' + modalKey} info={currentItem} visible={addModalVisible} handleClose={handleCloseAddModal} /> 
      <IntegralDetailModal key={'integral_' + modalKey} info={currentItem} visible={visible} handleCancel={() => setVisible(false)} />
    </div>
  )
}

export default Page
