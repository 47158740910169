// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Modal, Spin, Empty, Pagination  } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { API } from '../../../../api'
import '../index.scss'
import { fileResourcePath } from '../../../../utils/index'
interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC<any> = ({visible, avatarChild, avatarObj}) => {
  const { message: Message } = App.useApp()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 15
  });
  const [total, setTotal] = useState<any>(0)
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [avatarList, setAvatarList] = useState<[]>([])
  const [avatarPath, setAvatarPath] = useState<[]>([])

  useEffect(() => {
    if (visible) {
      getAvatarList()
      avatarObj && setAvatarPath(avatarObj)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo, visible, avatarObj])

  const getAvatarList = async () => { // 滚动加载分页
    setTableLoading(true)
    const res: any = await API.userHeadList({
      page: pageInfo.current,
      size: pageInfo.pageSize
    })
    if (!res) return false
    setTableLoading(false)
    const { code, message, data } = res
    if (code === '000000') {
      setAvatarList(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      avatarChild(true, avatarPath)
    } catch (errorInfo) {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmLoading(false)
    avatarChild(false)
  }

  const onSelect = (ele: any) => {
    setAvatarPath(ele.headUrl)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }
  
  return (
    <Modal 
      title='选择头像'
      width={648} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="select-avatar-modal"
    >
      <div className='select-avatar-page'>
        {
          tableLoading ?
          <div className='empty-wrap'><Spin /></div> :
          avatarList.length === 0 ?
          <div className='empty-wrap'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div> :
          <div className='yg-page-section photo-section'>
            {avatarList.map((ele: any, index: number) => (
              <div className='photo-item'>
                <img onClick={() => onSelect(ele)} src={fileResourcePath(ele.headUrl)} alt='图片' className={`img-item ${avatarPath === ele.headUrl ? "border-yellow" : ""}`} />
                {
                  avatarPath === ele.headUrl && 
                  <div className='have-select'>
                    <CheckCircleOutlined />
                  </div>
                }
              </div>
            ))}
            {
                total  + '' !== '0' &&
                <Pagination
                  current={pageInfo.current}
                  pageSize={pageInfo.pageSize}
                  pageSizeOptions={[15, 30, 45, 60]}
                  showTotal={(total) => `共 ${total} 条`}
                  total={total}
                  onChange={handlePageChange}
                />
              }
          </div>
        }
      </div>
    </Modal>
  )
}

export default Page
