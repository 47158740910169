// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { Table, Button, App } from 'antd'
import { API } from '../../../api'
import './index.scss'
import { RedoOutlined } from '@ant-design/icons'
import AddRole from './components/add_role'

interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC = () => {
  const { message: Message } = App.useApp()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [dataSource, setDataSource] = useState<any>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, record: any, index: number) => {
        return index + 1
      },
      width: '30%',
    },
    {
      title: '角色名称',
      dataIndex: 'roleName',
      key: 'roleName',
      width: '40%',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: '30%',
    },
  ]

  const scrollX: number = columns.reduce((pre: number, curr: any) => {
    return pre + (curr.width || 100)
  }, 0)

  const [modalVisible, setModalVisible] = useState<Boolean>(false)

  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo])

  const getList = async () => {
    setTableLoading(true)
    const res: any = await API.sysRoleList({
      page: pageInfo.current,
      size: pageInfo.pageSize
    })
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handleSearch = () => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const handleAdd = () => {
    setModalVisible(true)
  }

  const modalOperate = (isOk: Boolean) => {
    setModalVisible(false)
    if (isOk) {
      handleSearch()
    }
  }

  return (
    <div className='role-list-page yg-page'>
      <div className='role-list-header yg-page-header'>
        <span>角色列表</span>
        <Button className='yg-refresh-btn' onClick={handleSearch}>
          <RedoOutlined />
        </Button>
        <Button type="primary" onClick={handleAdd}>新增角色</Button>
      </div>
      <div className='yg-page-container' style={{ height: 'calc(100% - 54px)' }}>
        <div className='yg-page-main'>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={tableLoading}
            scroll={{ x: scrollX }}
            pagination={{
              ...pageInfo,
              total,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共 ${total} 条`,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
      <AddRole visible={modalVisible} modalChild={modalOperate} />
    </div>
  )
}

export default Page
