import React, { useState, useEffect } from 'react'
import { Rate, Table, DatePicker, Select, Input, App, Button, Modal } from 'antd'
import { weekDayMap, courseStatusMap, courseStatusList, courseTypeMap, courseTypeList, courseDifficultyList } from '../../../../../constants'
import ClassDetailModal from '../../../../../components/class_detail_modal'
import EditModal from '../edit_modal'
import ReserveDetailModal from '../reserve_detail_modal'
import { formatDate } from '../../../../../utils'
import { API } from '../../../../../api'
import { RedoOutlined } from '@ant-design/icons'
import './index.scss'

const { RangePicker } = DatePicker

interface IPageInfo {
  current: number;
  pageSize: number;
}

interface IProps {
  refreshKey: string;
}

const Page: React.FC<IProps> = ({ refreshKey }) => {
  const { message: Message } = App.useApp()
  const [modal, contextHolder] = Modal.useModal();
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [searchParams, setSearchParams] = useState<any>({
    courseDate: undefined,
    reserveTime: undefined,
    courseStatus: undefined,
    courseName: undefined,
    courseType: undefined,
    courseClassroomId: undefined,
    courseVenueUserId: undefined,
    courseDifficulty: undefined,
  });
  const [dataSource, setDataSource] = useState<any>([{ id: 1, courseName: 'xxxx' }])
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false) 
  const [currentItem, setCurrentItem] = useState<any>({}) 
  const [timeSlotList, setTimeSlotList] = useState<any>([])
  const [classroomList, setClassroomList] = useState<any>([])
  const [coachList, setCoachList] = useState<any>([])
  const [detailVisible, setDetailVisible] = useState<boolean>(false)
  const [editVisible, setEditVisible] = useState<boolean>(false)

  const columns: any = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (value: any, record: any, index: number) => {
        return index + 1
      },
      width: 80,
      fixed: 'left',
    },
    {
      title: '课程名称',
      dataIndex: 'courseName',
      key: 'courseName',
      width: 150,
      ellipsis: true
    },
    {
      title: '课程教室',
      dataIndex: 'classroomName',
      key: 'classroomName',
      width: 150,
      ellipsis: true
    },
    {
      title: '课程教练',
      dataIndex: 'userName',
      key: 'userName',
      width: 150,
      ellipsis: true
    },
    {
      title: '课程类型',
      dataIndex: 'courseType',
      key: 'courseType',
      width: 130,
      render: (text: any) => courseTypeMap[text] || '',
    },
    {
      title: '课程状态',
      dataIndex: 'courseStatus',
      key: 'courseStatus',
      width: 130,
      render: (text: any) => courseStatusMap[text]?.text || '',
      ellipsis: true
    },
    {
      title: '课程日期',
      dataIndex: 'courseDate',
      key: 'courseDate',
      width: 150,
      render: (text: any) => formatDate(text) || '',
      ellipsis: true
    },
    {
      title: '课程时间',
      dataIndex: 'courseSlot',
      key: 'courseSlot',
      width: 180,
      render: (text: any, record: any) => text ? `${text}(${record.courseTimes || 0}分钟)` : '',
      ellipsis: true
    },
    {
      title: '课程周期',
      dataIndex: 'courseWeekDay',
      key: 'courseWeekDay',
      width: 150,
      render: (text: any) => weekDayMap[text] || '',
    },
    {
      title: '课程难度',
      dataIndex: 'coursedifficulty',
      key: 'coursedifficulty',
      width: 180,
      render: (text: any, record: any) => (<div key={record.id + '' + text} className='rate-col'>
        <Rate disabled defaultValue={text} />
      </div>),
    },
    {
      title: '操作',
      dataIndex: 'operate',
      key: 'operate',
      width: 270,
      fixed: 'right',
      render: (text: any, record: any) => <div className='yg-operate-col'>
        <span className='yg-operate-btn' onClick={() => handleOperate('detail', record)}>详情</span>
        <span className='yg-operate-btn' onClick={() => handleOperate('reserveDetail', record)}>预约情况</span>
        <span className='yg-operate-btn' onClick={() => handleOperate('delete', record)}>删除</span>
        <span className='yg-operate-btn' onClick={() => handleOperate('edit', record)}>编辑</span>
        <span className='yg-operate-btn' onClick={() => handleOperate('deleteForce', record)}>超级删除</span>
      </div>
    },
  ]

  const scrollX: number = columns.reduce((pre: number, curr: any) => {
    return pre + (curr.width || 100)
  }, 0)

  useEffect(() => {
    getTimeSlot()
    getCoachList()
    getClassroomList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleSearch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey])

  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo])

  const getList = async () => {
    let params: any = {
      ...searchParams,
      page: pageInfo.current,
      size: pageInfo.pageSize,
      courseStartDate: searchParams.courseDate ? searchParams.courseDate[0].format('YYYY-MM-DD')  : undefined,
      courseEndDate: searchParams.courseDate ? searchParams.courseDate[1].format('YYYY-MM-DD') : undefined,
    }
    delete params.courseDate
    setTableLoading(true)
    const res: any = await API.getCourseList(params)
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const getTimeSlot = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.timeSlotList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setTimeSlotList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const getCoachList = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.coachList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setCoachList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const getClassroomList = async () => {
    let params: any = {
      page: 1,
      size: 10000,
    }
    const res: any = await API.classroomList(params)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setClassroomList(data?.list || [])
    } else {
      Message.error(message)
    }
  }

  const handleFilterChange = (key: string, value: any) => {
    setSearchParams({
      ...searchParams,
      [key]: value
    })
  }

  const handleSearch = () => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const handleClose = (isOk: boolean) => {
    if (isOk) {
      getList()
    }
    setEditVisible(false)
  }

  const handleOperate = (type: string, record?: any) => {
    setCurrentItem(record)
    switch (type) {
      case 'detail': {
        setDetailVisible(true)
        break
      }
      case 'reserveDetail': {
        setVisible(true)
        break
      }
      case 'delete': {
        handleDelete(record)
        break
      }
      case 'deleteForce': {
        handleDeleteForce(record)
        break
      }
      case 'edit': {
        setEditVisible(true)
        break
      }
      default:
        break
    }
  }
  const handleDelete = async (record: any) => {
    const confirmed = await modal.confirm({
      title: '提示',
      content: (
        <>确认删除课程【{record.courseName}】吗？</>
      ),
    });
    if (confirmed) {
      deleteReq(record)
    }
  }

  const deleteReq = async (record: any) => {
    const res: any = await API.deleteCourse({ id: record.id })
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success('删除成功')
      handleSearch()
    } else {
      Message.error(message)
    }
  }

  const handleDeleteForce = async (record: any) => {
    const confirmed = await modal.confirm({
      title: '提示',
      content: (
        <>确认超级删除课程【{record.courseName}】吗？</>
      ),
    });
    if (confirmed) {
      deleteForceReq(record)
    }
  }

  const deleteForceReq = async (record: any) => {
    const res: any = await API.deleteCourseFinal({ id: record.id })
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success('删除成功')
      handleSearch()
    } else {
      Message.error(message)
    }
  }

  return (
    <div className='class-list-tab'>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程日期</span>
          <RangePicker
            value={searchParams.courseDate}
            onChange={(date) => handleFilterChange('courseDate', date)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>预约时间段</span>
          <Select
            allowClear
            placeholder="请选择预约时间段"
            value={searchParams.reserveTime}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('reserveTime', value)}
            options={timeSlotList.map((ele: any) => ({
              label: ele.timeSlot, value: ele.timeSlot
            }))}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程状态</span>
          <Select
            allowClear
            placeholder="请选择课程状态"
            value={searchParams.courseStatus}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('courseStatus', value)}
            options={courseStatusList}
          />
        </div>
      </div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程名称</span>
          <Input
            allowClear
            placeholder="请输入课程名称"
            value={searchParams.courseName}
            style={{ width: 180 }}
            onChange={(e) => handleFilterChange('courseName', e.target.value)}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程教室</span>
          <Select
            allowClear
            placeholder="请选择课程教室"
            value={searchParams.courseClassroomId}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('courseClassroomId', value)}
            options={classroomList.map((ele: any) => ({
              label: ele.classroomName, value: ele.id
            }))}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程教练</span>
          <Select
            allowClear
            placeholder="请选择课程教练"
            value={searchParams.courseVenueUserId}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('courseVenueUserId', value)}
            options={coachList.map((ele: any) => ({
              label: ele.userName, value: ele.id
            }))}
          />
        </div>
      </div>
      <div className='yg-page-filter'>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程类型</span>
          <Select
            allowClear
            placeholder="请选择课程类型"
            value={searchParams.courseType}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('courseType', value)}
            options={courseTypeList}
          />
        </div>
        <div className='yg-page-filter-item'>
          <span className='yg-page-filter-label'>课程难度</span>
          <Select
            allowClear
            placeholder="请选择课程难度"
            value={searchParams.courseDifficulty}
            style={{ width: 180 }}
            onChange={(value) => handleFilterChange('courseDifficulty', value)}
            options={courseDifficultyList}
          />
        </div>
        <div className='yg-page-filter-item'>
          <Button className='yg-ghost-btn' onClick={handleSearch}>查询</Button>
        </div>
        <Button className='yg-refresh-btn' onClick={handleSearch}>
          <RedoOutlined />
        </Button>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        loading={tableLoading}
        scroll={{ x: scrollX }}
        pagination={{
          ...pageInfo,
          total,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
          onChange: handlePageChange,
        }}
      />
      {contextHolder}
      <ReserveDetailModal courseInfo={currentItem} visible={visible} handleCancel={() => setVisible(false)} />
      <ClassDetailModal courseInfo={currentItem} visible={detailVisible} handleCancel={() => setDetailVisible(false)} />
      <EditModal courseInfo={currentItem} visible={editVisible} handleClose={handleClose} />
    </div>
  )
}

export default Page
