// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { App, Table, Button } from 'antd'
import { API } from '../../../api'
import './index.scss'
import { fileResourcePath } from '../../../utils/index'
import { RedoOutlined } from '@ant-design/icons'
import AddAvatar from './components/add_avatar';
interface IPageInfo {
  current: number;
  pageSize: number;
}

const Page: React.FC = () => {
  const { message: Message } = App.useApp()
  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    current: 1,
    pageSize: 10
  });
  const [total, setTotal] = useState<any>(0)
  const [dataSource, setDataSource] = useState<any>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      width: '30%',
      render: (value: any, record: any, index: number) => {
        return index + 1
      }
    },
    {
      title: '图片',
      dataIndex: 'headUrl',
      key: 'headUrl',
      width: '40%',
      render: (text: any) => (<div className='yg-weixin-url-col'>
        <img src={fileResourcePath(text)} alt='图片' />
      </div>)
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
  ]

  const scrollX: number = columns.reduce((pre: number, curr: any) => {
    return pre + (curr.width || 100)
  }, 0)

  const [modalVisible, setModalVisible] = useState<Boolean>(false)

  useEffect(() => {
    getList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo])

  const getList = async () => {
    setTableLoading(true)
    const res: any = await API.userHeadList({
      page: pageInfo.current,
      size: pageInfo.pageSize,
    })
    setTableLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setDataSource(data?.list || [])
      setTotal(data?.total || 0)
    } else {
      Message.error(message)
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let newPage: number = page
    if (pageSize !== pageInfo.pageSize) {
      newPage = 1
    }
    setPageInfo({
      current: newPage,
      pageSize
    })
  }

  const handleOperate = () => {
    setModalVisible(true)
  }

  const handleSearch = () => {
    setPageInfo({
      ...pageInfo,
      current: 1,
    })
  }

  const modalOperate = (isOk: Boolean) => {
    setModalVisible(false)
    if (isOk) {
      handleSearch()
    }
  }

  return (
    <div className='avatar-list-page yg-page'>
      <div className='avatar-list-header yg-page-header'>
        <span>头像列表</span>
        <Button className='yg-refresh-btn' onClick={handleSearch}>
          <RedoOutlined />
        </Button>
        <Button type="primary" onClick={() => handleOperate()}>新增头像</Button>
      </div>
      <div className='yg-page-container' style={{ height: 'calc(100% - 54px)' }}>
        <div className='yg-page-main'>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            loading={tableLoading}
            scroll={{ x: scrollX }}
            pagination={{
              ...pageInfo,
              total,
              showSizeChanger: true,
              showQuickJumper: true,
              showTotal: (total) => `共 ${total} 条`,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
      <AddAvatar visible={modalVisible} modalChild={modalOperate} />
    </div>
  )
}

export default Page
