// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useRef } from 'react'
import { App, Modal, Form, Upload, Input, InputNumber, Select } from 'antd'
import { videoTypeList } from '../../../../constants'
import { API } from '../../../../api'
import lodash from 'lodash'
import { transformBase64ToBlob, convertBlobToFile, fileResourcePath } from '../../../../utils/index'
import '../index.scss'

const Page: React.FC<any> = ({visible, modalChild}) => {
  const { message: Message } = App.useApp()
  const { TextArea } = Input;
  const [form] = Form.useForm()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [viPath, setViPath] = useState<string>('')
  const [viCoverPath, setViCoverPath] = useState<string>('')
  const videoRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    if (visible) {
      setViPath('')
      setViCoverPath('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const beforeUpload = (file: any) => {
    const isVideo = file.type === 'video/ogg' || file.type === 'video/mp4' || file.type === 'video/webm';
    const isLt20M = file.size / 1024 / 1024 <= 20;

    if(isVideo && isLt20M) {
      handleUpload(file)
    } else {
      Message.error('仅支持ogg，mp4，webm格式,视频大小不大于20M!');
    }
    return false;
  }

  const handleUpload = async (file: any) => {
    const res: any = await API.normalUpload({
      file: file
    })
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setViPath(data)
      getFirstPath(file)
    } else {
      Message.error(message)
    }
  }

  // 根据视频路径获取视频封面路径
  const getFirstPath = (file:any) => {
    const video: any = videoRef.current;
    const canvas: any = canvasRef.current;
    const context = canvas.getContext('2d');
    const reader = new FileReader();

    reader.onload = (event: any) => {
      video.src = event.target.result;
      video.oncanplay = ()=>{
        // 视频时长
        form.setFieldsValue({
          viTimes: video.duration,
        })
      }
      video.onloadedmetadata = () => {
        video.currentTime = 0;
        video.onseeked = () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          canvas.toBlob((blob: any) => {
            // 这里的blob就是第一帧图片的File对象
            let name = file.name.split('.')[0] + '.png'
            let imgFile = convertBlobToFile(blob , name , 'image/png')
            uploadFirstFrame(imgFile);
            
          });
        };
      };
    };
    reader.readAsDataURL(file);
  }

  const uploadFirstFrame = async (file: any) => {
    const res: any = await API.normalUpload({
      file: file
    })
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      setViCoverPath(data)
      
    } else {
      Message.error(message)
    }
  }

  const validatorCheckName = async (rule: any, value: any) => {
    if (value) {
      const res: any = await API.checkVideoName({
        viName: value
      })
      if (!res) return false
      const { code, data, message } = res
      if (code === '000000') {
        if (data) {
          return Promise.resolve()
        } else {
          return Promise.reject('视频名称重复')
        }
      } else {
        return Promise.reject(message)
      }
    } else {
      return Promise.reject('请输入视频名称')
    }
  }
  
  const submitForm = async (values: any) => {
    setConfirmLoading(true)
    const res: any = await API.uploadVideo({
      viName: values.viName,
      viType: values.viType,
      viTimes: values.viTimes,
      viPath: viPath,
      viCoverPath: viCoverPath,
      viDesc: values.viDesc 
    })
    setConfirmLoading(false)
    if (!res) return false
    const { code, message, data } = res
    if (code === '000000') {
      modalChild(true)
      Message.success('操作成功')
      handleCancel()
    } else {
      Message.error(message)
    }
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      submitForm(values)
    } catch (errorInfo) {
      setConfirmLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmLoading(false)
    modalChild(false)
  }
  
  return (
    <Modal 
      title="上传视频" 
      width={500} 
      open={visible} 
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onOk={handleOk} 
      onCancel={handleCancel}
      wrapClassName="add-account-modal"
    >
      <Form preserve={false} form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
        <Form.Item 
          label="视频名称" 
          name="viName"
          required
          rules={[{ validator: validatorCheckName }]}
          validateTrigger="onBlur"
        >
          <Input placeholder="请输入视频名称" />
        </Form.Item>
        <Form.Item 
          label="视频类型" 
          name="viType"
          rules={[{ required: true, message: '请选择视频类型' }]}
        >
          <Select
            allowClear
            placeholder="请选择视频类型"
            options={videoTypeList}
          />
        </Form.Item>
        <Form.Item 
          label="选择" 
          name="picture"
          rules={[{ required: true, message: '请选择视频' }]}
        >
          <Upload
            name="file"
            listType="picture-card"
            className="video-uploader"
            showUploadList={false}
            fileList={[]}
            accept="video/ogg, video/mp4, video/webm"
            beforeUpload={beforeUpload}
          >
            {viCoverPath ? <img src={fileResourcePath(viCoverPath)} alt="图片" width={200} height={100} /> : (
              <div className='click-wrap'>
                <img src={require('../../../../assets/images/upload.png')} alt="上传" width={24} />
                <p className='click-text'>点击选择视频</p>
                <div className='video-tips'>
                  <p>* 支持ogg，mp4，webm格式文件；</p>
                  <p>* 视频大小不大于20M；</p>
                </div>
                <video ref={videoRef} hidden style={{ display: "none" }}></video>
                <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item 
          label="视频时长" 
          name="viTimes"
          required
          rules={[
            {
              validator: (rule, value) => {
                if (value) {
                  if (value === 0 || (value && value < 0)) {
                    return Promise.reject("视频时长必须大于0")
                  } else {
                    return Promise.resolve()
                  }
                } else {
                  return Promise.reject("请输入视频时长")
                }
              }
            }
          ]}
        >
          <InputNumber placeholder="请输入视频时长" disabled style={{width: '150px'}} />
        </Form.Item>
        <Form.Item 
          label="视频描述" 
          name="viDesc"
          rules={[{ required: false, message: '请输入视频描述' }]}
        >
          <TextArea placeholder='请输入视频描述' />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Page
