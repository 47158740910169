import React, { useState, useEffect } from 'react'
import { Modal, App, Form, InputNumber, DatePicker, Radio } from 'antd'
import { cardTypeList, numOperateTypeList } from '../../../../../constants'
import { API } from '../../../../../api'
import './index.scss'
import dayjs from 'dayjs'

interface IProps {
  info: any;
  visible: boolean;
  handleClose: Function;
}

const Page: React.FC<IProps> = ({ info, visible, handleClose }) => {
  const { message: Message } = App.useApp()
  const [form] = Form.useForm()
  const [selectCardType, setSelectCardType] = useState<string>('1')

  const formItemLayoutNormal = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  }

  const formItemLayoutOneLine = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  }

  useEffect(() => {
    if (visible) {
      init()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const init = () => {
    let oldCardType = info.cardType
    if (oldCardType + '' === '2') {
      setSelectCardType('2')
      form.setFieldsValue({
        cardType: '2',
        oldExpireDate: info.expireDate ? dayjs(info.expireDate, 'YYYY-MM-DD') : dayjs(),
        expireDate: undefined,
      })
    } else {
      setSelectCardType('1')
      form.setFieldsValue({
        cardType: '1',
        numOperateType: '1',
        courseNum: undefined,
        oldExpireDate: info.expireDate ? dayjs(info.expireDate, 'YYYY-MM-DD') : dayjs(),
        expireDate: undefined,
      })
    }
  }

  const handleSubmit = async () => {
    try {
      const values: any = await form.validateFields();
      let params: any = {
        customerId: info.id,
        cardNo: info.cardNo || undefined,
        cardType: values.cardType,
        expireDate: values.expireDate.format('YYYY-MM-DD'),
      }
      if (values.cardType === '1') {
        params.numOperateType = values.numOperateType
        params.courseNum = values.courseNum
      }
      newCard(params)
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  }

  const newCard = async (params: any) => {
    const res: any = await API.createCard(params)
    if (!res) return false
    const { code, message } = res
    if (code === '000000') {
      Message.success(info.cardNo ? '续费成功' : '开卡成功')
      handleClose(true)
    } else {
      Message.error(message)
    }
  } 

  const handleCardTypeChange = (e: any) => {
    let val = e.target.value
    setSelectCardType(val)
    if (val === '2') {
      form.setFieldsValue({
        oldExpireDate: dayjs(),
      })
    } else {
      form.setFieldsValue({
        numOperateType: '1',
      })
    }
  }

  return (
    <Modal
      title={info.cardNo ? '续费' : '开卡'}
      open={visible}
      onOk={() => handleSubmit()}
      onCancel={() => handleClose(false)}
      width={650}
      wrapClassName="new-card-modal"
    >
      <div className='yg-user-info-card'>
        <div className='card-title'>
          <span>您正在操作的会员：</span>
          <span>【{info.weixinName}】</span>
        </div>
        <div className='info-list'>
          <div className='info-item'>
            <span>会员号码</span>
            <span>{info.customerPhone || '--'}</span>
          </div>
          {info.cardNo && <div className='info-item'>
            <span>会员卡号</span>
            <span>{info.cardNo}</span>
          </div>}
          {selectCardType === '1' && <div className='info-item'>
            <span>当前剩余课程次数</span>
            <span>{info.courseNum || 0}</span>
          </div>}
        </div>
      </div>
      <Form form={form}>
        <Form.Item
          className='one-line-item'
          {...formItemLayoutOneLine}
          name="cardType"
          label="会员卡类型"
          rules={[{ required: true, message: '请选择会员卡类型' }]}
        >
          <Radio.Group onChange={handleCardTypeChange} options={[cardTypeList[0], cardTypeList[1]]} />
        </Form.Item>
        {selectCardType === '1' && <div className='form-bg'>
          <Form.Item
            {...formItemLayoutNormal}
            name="numOperateType"
            label="操作类型"
            rules={[{ required: true, message: '请选择操作类型' }]}
          >
            <Radio.Group options={numOperateTypeList} />
          </Form.Item>
          <Form.Item
            {...formItemLayoutNormal}
            dependencies={['numOperateType']}
            name="courseNum"
            label="课程次数"
            rules={[
              { required: true, message: '请输入课程次数' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('numOperateType') === '2' && value > info.courseNum) {
                    return Promise.reject(new Error('扣减数量不可超过剩余课程次数'));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber precision={0} style={{ width: '100%' }} min={1} placeholder="请输入课程次数" />
          </Form.Item>
        </div>}
        <div className='form-bg'>
          <Form.Item
            {...formItemLayoutNormal}
            name="oldExpireDate"
            label="当前截止日期"
            className='without-margin-bottom'
            rules={[{ required: false, message: '请选择当前截止日期' }]}
          >
            <DatePicker disabled style={{ width: '100%' }} placeholder="请选择当前截止日期" />
          </Form.Item>
          <Form.Item
            {...formItemLayoutNormal}
            name="expireDate"
            label="调整截止日期到"
            className='without-margin-bottom'
            rules={[{ required: true, message: '请选择截止日期' }]}
          >
            <DatePicker style={{ width: '100%' }} placeholder="请选择截止日期" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default Page
