// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react'
import { Tabs, Button } from 'antd'
import Calendar from './components/calendar'
import List from './components/list'
import AddModal from './components/add_modal'
import './index.scss'

const Page: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('1')
  const [visible, setVisible] = useState<boolean>(false)
  const [refreshKey, setRefreshKey] = useState<string>(+new Date() + '')

  const tabs: any[] = [
    { label: `日历版`, key: '1', children: null, },
    { label: `列表版`, key: '2', children: null, },
  ]

  const handleTabChange = (key: string) => {
    setActiveTab(key)
  }

  const handleClose = (isOk: boolean) => {
    if (isOk) {
      setRefreshKey(+new Date() + '')
    }
    setVisible(false)
  }

  return (
    <div className='class-list-page yg-page'>
      <div className='yg-page-header'><span>课程列表</span></div>
      <div className='yg-page-filter' style={{ paddingBottom: 0,  marginTop: -10 }}>
        <Tabs className='class-mode-tabs' activeKey={activeTab} items={tabs} onChange={handleTabChange} />
        <Button type="primary" className='add-class-btn' onClick={() => setVisible(true)}>新增课程</Button>
      </div>
      <div className='yg-page-container' style={{ height: 'calc(100% - 54px - 32px)' }}>
        <div className='yg-page-main' style={ activeTab === '1' ? { 'overflow': 'hidden', height: '100%' } : {}}>
          {activeTab === '1' ? <Calendar refreshKey={refreshKey} /> : <List refreshKey={refreshKey} />}
        </div>
      </div>
      <AddModal visible={visible} handleClose={handleClose} />
    </div>
  )
}

export default Page
